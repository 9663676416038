import React, { useState } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Form, Row, Col, Spin, Modal } from 'antd'
import { inject } from 'mobx-react'
import { compose } from 'recompose'
import moment from 'moment-timezone'
import eventQuery from '~/graphql/queries/event.gql'

const labelSpan = { xs: { span: 24 }, sm: { span:3, offset: 1} }
const subLabelSpan = { xs: { span: 24 }, sm: { span: 5, offset: 9 } }
// const wrapperSpan = { xs: { span: 6, offset: 2 }, sm: { span: 4, offset: 2 } }
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { offset: 1, span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
}

// const formItemLongLayout = {
//   labelCol: {
//     xs: { span: 12 },
//     sm: { offset: 1, span: 4 },
//   },
//   wrapperCol: {
//     xs: { span:12 },
//     sm: { span: 12},
//   },
// }

// const tailFormItemLayout = {
//   wrapperCol: {
//     xs: {
//       span: 24,
//       offset: 0,
//     },
//     sm: {
//       span: 8,
//       offset: 10,
//     },
//   },
// }

const EventView = ({ match, user }) => {

  const intl = useIntl()


  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const isModalClose = () => {
    setIsModalOpen(false);
  };

  const { eventId } = match.params
  const { error, loading, data } = useQuery(eventQuery, {
    variables: { _id: eventId },
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  if (error) {
    return 'Error...'
  }
  const { event } = data ?? {}

  return (
    <Form className='nonPrintDiv' style={{ margin: '0 4em', padding: '2em 0 4em 0' }}>
      <div style={{ background: 'white', border: '1px solid LightGrey', borderRadius: '25px', padding: '1em' }}> 
        <Row style={{ color: 'black', fontSize: '1.2em', background: 'WhiteSmoke', borderRadius: '5px', padding: '10px', margin: '15px' }}>
          <Col>{intl.formatMessage({
              id: 'app.generalTitle',
            })}</Col>
        </Row>
        <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.eventTitle' defaultMessage='Event Title' />}>
          <span>{event.title}</span>
        </Form.Item> 
        {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) && (
        <>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.eventProvince' defaultMessage='event Province' />}>{event.province}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.eventDate' defaultMessage='event Date' />}>{moment(event?.startDate).format('DD/MM/YYYY')}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.singlePhotoPrice' defaultMessage='single PhotoPrice' />}>{event.singlePhotoPrice}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.broker' defaultMessage='broker' />}>{event.broker?.formalName}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.salesperson' defaultMessage='salesperson' />}>{event.salesperson?.formalName}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.salespersonShare' defaultMessage='salespersonShare' />}>{event.salespersonShare}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.salespersonRevenue' defaultMessage='salespersonRevenue' />}>{event.financial?.balance}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.isPartner' defaultMessage='Is Partner' />}>{event.officialPartner ? 'true' : 'false'}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left'label={<FormattedMessage id='app.raceType' defaultMessage='Race Type' />}>{event.raceType}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.raceDistance' defaultMessage='Race Distance' />}>{event.raceDistance}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.participantCount' defaultMessage='Participant Count' />}>{event.participantCount}</Form.Item>

          {event?.officialPartner && (
            <>
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.partner' defaultMessage='Partner' />}>{event?.organizer?.formalName}</Form.Item>
              {event?.organizerId && (
                <>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.companyName' defaultMessage='Company Name' />}>{event?.organizer?.companyName}</Form.Item> 
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.residence' defaultMessage='Address' />}>{event?.organizer?.address}</Form.Item>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.accountNumber' defaultMessage='Account Number' />}>{event?.organizer?.bankAccount?.number}</Form.Item>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.bank' defaultMessage='Bank' />}>{event?.organizer?.bankAccount?.brand}</Form.Item>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.accountName' defaultMessage='Account Name' />}>{event?.organizer?.bankAccount?.name}</Form.Item>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.partnerShare' defaultMessage='Partner Share' />}>{`${event?.organizer?.organizerShare} ${
                      event?.organizer?.organizerShareUnit === 'fixed' ? 'Baht' : '%'}`}
                  </Form.Item>  
                </>
              )}
                  <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.duration' defaultMessage='Duration' />}>{event?.shareInterval ?? moment(event.shareEndsAt).diff(event.startDate, 'days') + 1 ?? (event.isOfficialPartner ? 30 : undefined)}
                    <span style={{ textAlign: 'left', width: '100%', padding: '0px 10px' }}>วัน (นับจากวันที่เริ่มกิจกรรม)</span>
                  </Form.Item>
                  <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.broker' defaultMessage='Broker' />}>{event?.broker?.formalName}</Form.Item>
              {event?.brokerId && (
                <>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.companyName' defaultMessage='Company Name' />}>{event?.broker?.companyName}</Form.Item>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.residence' defaultMessage='Address' />}>{event?.broker?.address}</Form.Item>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.accountNumber' defaultMessage='Account Number' />}>{event?.broker?.bankAccount?.number}</Form.Item>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.bank' defaultMessage='Bank' />}>{event?.broker?.bankAccount?.brand}</Form.Item>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.accountName' defaultMessage='Account Name' />}>{event?.broker?.bankAccount?.name}</Form.Item>
                  <Form.Item {...subLabelSpan} labelAlign='left' label={<FormattedMessage id='app.brokerShare' defaultMessage='Broker Share' />}>{`${event?.broker?.brokerShare} ${event?.broker?.brokerShareUnit === 'fixed' ? 'Baht' : '%'}`}</Form.Item>
                </>
              )}
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.informationDocument' defaultMessage='Information Document for partner' />}>{event?.informationDocumentUrl && <img src={event.informationDocumentUrl} alt='informationDocumentUrl' height={100} />}</Form.Item>
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.contractDocument' defaultMessage='Contract Document for partner' />}>{event?.contractDocumentUrl && <img src={event.contractDocumentUrl} alt='contractDocumentUrl' height={100} />}</Form.Item>
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.eventFrame' defaultMessage='Event Frame' />}>{event?.photoFrame?.portraitPreviewUrl && <img src={event?.photoFrame?.portraitPreviewUrl} alt='portraitPreviewUrl' height={100} />}
                  {event?.photoFrame?.landscapePreviewUrl && (
                    <img src={event?.photoFrame?.landscapePreviewUrl} alt='landscapePreviewUrl' height={100} />
                  )}
                  {/* <FrameImageInput portraitPreviewUrl={portraitPreviewUrl} landscapePreviewUrl={landscapePreviewUrl} /> */}
              </Form.Item>
            </>
          )}
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.enablePackages' defaultMessage='Enable packages' />}>{event?.photoPackageEnabled ? 'true' : 'false'}</Form.Item>
          {event?.photoPackageEnabled && (
            <>
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.photoPackageIds' defaultMessage='Packages' />}></Form.Item>
              {event.photoPackages.length > 0 &&
                event.photoPackages.map((photoPackage) => {
                  return (
                    <Row key={photoPackage._id}>
                      <Col xs={{ span: 24 }} sm={{ span: 10, offset: 14 }}>
                        {photoPackage.name}
                      </Col>
                    </Row>
                  )
                })}
            </>
          )}
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.downloadMode' defaultMessage='Download Mode' />}>{event.photoDownloadMode}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.hidden' defaultMessage='Hidden' />}>{event.hidden ? 'true' : 'false'}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.openSearchOn' defaultMessage='Open Search On' />}>{event?.searchableAt ? `${moment(event.searchableAt)}` : ''}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.startUploadOn' defaultMessage='Start Upload On' />}>{event?.uploadableAt ? `${moment(event.uploadableAt)}` : ''}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.uploadNotice' defaultMessage='Upload Notice' />}>{event?.uploadNotice}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.faceSearch' defaultMessage='Face Search' />}>{event?.photoTagFaces ? 'true' : 'false'}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.bibSearch' defaultMessage='BIB Search' />}>{event?.photoTagText ? 'true' : 'false'}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.approved' defaultMessage='Approved' />}>{event?.approved ? 'true' : 'false'}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.registrationMethod' defaultMessage='Registration Method' />}>{event?.registrationMethod}</Form.Item>
          <Row><Col {...labelSpan}><Link style={{fontSize : '1em'}} onClick={showModal}>รายชื่อช่างภาพ</Link></Col></Row>
          <Modal title="รายชื่อช่างภาพ" visible={isModalOpen} footer={null} onCancel={isModalClose}>
            {event?.uploads?.map((upload) => {return <p>{upload?.photographer?.displayName}</p>})}
          </Modal>
          {['auto', 'manual'].includes(event?.registrationMethod) && (
            <>
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.photographerLimit' defaultMessage='Photographer Limit' />}>{event?.photographerNumber}</Form.Item>
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.registrationStartsAt' defaultMessage='Registration starts at' />}>{event?.registrationStartsAt ? `${moment(event.registrationStartsAt)}` : ''}</Form.Item>
              <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.registrationEndsAt' defaultMessage='Registration ends at' />}>{event?.registrationEndsAt ? `${moment(event.registrationEndsAt)}` : ''}</Form.Item>
            </>
          )}
          <Row style={{ color: 'black', fontSize: '1.2em', background: 'WhiteSmoke', borderRadius: '5px', padding: '10px', margin: '15px' }}>
            <Col span={12}>{intl.formatMessage({
              id: 'app.incomeTitle',
            })}</Col>
          </Row>
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.incomeMsgRange',
            }, {
              day: 30
            })}`}>{moment(event?.startDate).format('DD/MM/YYYY')} ถึง {moment(event?.shareEndsAt).format('DD/MM/YYYY')}</Form.Item>
            {/* <Col span={6} offset={2}>รายรับ 30 วัน</Col> */}
            {/* <Col span={12} offset={2}>{moment(event?.startDate).format('DD/MM/YYYY')} ถึง {moment(event?.shareEndsAt).format('DD/MM/YYYY')}</Col> */}
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.salesMsgRange',
            }, {
              day: 30
            })}`}>???</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.brokerShare' defaultMessage='Broker Share' />}>{`${parseFloat(event.brokerShare).toFixed(2)}\t${event.brokerShareUnit === 'percentage' ? '%' : 'บาท'}`}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.organizerSales',
            })}`}><FormattedNumber value={event.brokerShareAmount} minimumFractionDigits={2} maximumFractionDigits={2} /></Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.partnerShare' defaultMessage='Partner Share' />}>{`${parseFloat(event.organizerShare).toFixed(2)}\t${event.organizerShareUnit === 'percentage' ? '%' : 'บาท'}`}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.partnerSales',
            })}`}><FormattedNumber value={event.organizerShareAmount} minimumFractionDigits={2} maximumFractionDigits={2} /></Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.photographerShare' defaultMessage='Photographer Share' />}>{`${parseFloat(event.photographerShare).toFixed(2)}\t${event.photographerShareUnit === 'percentage' ? '%' : 'บาท'}`}</Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.photographerSales',
            })}`}><FormattedNumber value={event.photographerShareAmount} minimumFractionDigits={2} maximumFractionDigits={2} /></Form.Item>
          <Row style={{ color: 'black', fontSize: '1.2em', background: 'WhiteSmoke', borderRadius: '5px', padding: '10px', margin: '15px' }}>
            <Col>{intl.formatMessage({
              id: 'app.overview',
            })}</Col>
          </Row>
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.orderAmount',
            })}`}> <FormattedNumber value={event?.photoSales?.reduce((a, b) => a + b.orderCount, 0)} /></Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.orderNotPromotionAmount',
            })}`}><FormattedNumber value={event?.photoSales?.reduce((a, b) => a + (b.packageId ? b.orderCount : 0), 0)} /></Form.Item>                        
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.promotionAndPackage',
            })}`}></Form.Item>
          {event.photoSales.length > 0 &&
            event.photoSales
              .slice()
              .sort((a, b) => a.photoPackage?.name.localeCompare(b.photoPackage?.name))
              .map((photoSale) => {
                return (
                  <Row key={photoSale.packageId}>
                    <Col offset={2} span={6}>
                      {photoSale.photoPackage?.name ?? 'No package used'}
                    </Col>
                    <Col span={2}>(บาท)</Col>
                    <Col span={4}>
                      <FormattedNumber value={photoSale.amount} minimumFractionDigits={2} maximumFractionDigits={2} />
                    </Col>
                    <Col span={2}>(รายการ)</Col>
                    <Col>{photoSale.orderCount}</Col>
                  </Row>
                )
              })}
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.discountCode',
            })}`}></Form.Item>
          {event.couponUsages.length > 0 &&
            event.couponUsages
              .sort((a, b) => a.couponCodeType?.name.localeCompare(b.couponCodeType?.name))
              .map((couponUsage) => {
                return (
                  <Row key={couponUsage.couponCodeTypeId}>
                    <Col offset={2} span={6}>
                      {couponUsage.couponCodeType?.name ?? 'No coupon used'}
                    </Col>
                    <Col span={2}>(บาท)</Col>
                    <Col span={4}>
                      <FormattedNumber value={couponUsage.amount} minimumFractionDigits={2} maximumFractionDigits={2} />
                    </Col>
                    <Col span={2}>(รายการ)</Col>
                    <Col>{couponUsage.orderCount}</Col>
                  </Row>
                )
              })}
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.salesAmount',
            })}`}><FormattedNumber value={event.totalShareAmount} minimumFractionDigits={2} maximumFractionDigits={2} /></Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={`4. ${intl.formatMessage({
              id: 'app.commissionBank',
            })}`}>0</Form.Item>
          {/* <Col span={4} offset={2}>
            <Form.Item label='4. ต่าธรรมเนียมธนาคาร (บาท)'/>
          </Col>
          <Col span={4}>0</Col> */}
          <Form.Item {...formItemLayout} labelAlign='left' label={`${intl.formatMessage({
              id: 'app.netBalance',
            })} 1, 2, 3, 4`}>0</Form.Item>
          {/* <Col span={4} offset={2}>
            <Form.Item label='คงเหลือหลังหัก 1, 2, 3, 4'/>
            <Col span={4}>0</Col>
          </Col> */}
        </>
      )}
      </div>
    </Form>
  )
}

export default compose(inject('user'))(EventView)
