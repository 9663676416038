import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Table, Button, Form, Input, Radio, Tag, Tooltip, Popover, DatePicker, message, Space } from 'antd'
import { CloseCircleOutlined, EditOutlined, HourglassOutlined, PictureOutlined, QuestionCircleOutlined, SearchOutlined, TeamOutlined, UploadOutlined, WarningOutlined, InfoCircleOutlined, CameraOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import { FormattedMessage, useIntl } from 'react-intl'
// import _orderBy from 'lodash/orderBy'

import eventItemsQuery from '~/graphql/queries/eventItems.gql'
import { inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import MaintenanceMessage from '~/components/MaintenanceMessage'
import EventDeleteButton from './EventDeleteButton'
import EventApproveButton from './EventApproveButton'
import { compose } from 'recompose'
import _omitBy from 'lodash/omitBy'
import _isEmpty from 'lodash/isEmpty'

const [
  SYMBOL_APPROVED,
  SYMBOL_REJECTED,
  SYMBOL_PENDING
] = ['✔', '✘', '⌛']

const reviewMap = {
  [SYMBOL_APPROVED]: true,
  [SYMBOL_REJECTED]: false,
  [SYMBOL_PENDING]: null
}

const Events = compose(
  inject('user'),
  inject('router')
)(({ user, router }) => {
  const [form] = Form.useForm()
  const { setFieldsValue } = form
  const { location } = router
  // const query = location.query
  const { p: page, s: titleSearch, f: eventFilter, from: _from, to: _to, r: reviewStatus, sort } = location.query

  const from = _from && moment(_from)
  const to = _to && moment(_to)

  const intl = useIntl()
  const approved = reviewMap[reviewStatus]
  const { error, loading, data } = useQuery(eventItemsQuery, {
    variables: {
      titleSearch,
      OR: eventFilter === 'register'
        ? [{ registrationMethod: 'auto' }, { registrationMethod: 'manual' }]
        : eventFilter === 'free'
          ? [{ registrationMethod: 'free' }, { registrationMethod: null }]
          : null,
      myEvent: eventFilter === 'myEvent',
      ...(from && to && {
        startDateRange: {
          gte: from?.toISOString(),
          lte: to?.toISOString()
        }
      }),
      ...(['broker', 'salesperson'].includes(user.role) && { createdByUserId: user._id }),
      // ...({ approved: reviewStatus && approved }),
      approved,
      sort,
      page: page && parseInt(page, 10)
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    setFieldsValue({ titleSearch, eventFilter: eventFilter ? eventFilter : 'all' })
  }, [titleSearch, eventFilter, setFieldsValue])

  if (error) {
    return message.error(error)
  }

  const handleSearch = (values) => {
    const { titleSearch } = values
    const { p, ...query } = location.query
    location.query = {
      ...query,
      s: titleSearch,
    }
  }

  const handleFilterChange = (e) => {
    const eventFilter = e.target.value
    const { p, ...query } = location.query
    location.query = _omitBy({
      ...query,
      f: eventFilter,
    }, _isEmpty)
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let sort
    if (sorter.order) {
      sort = `${sorter.columnKey}_${sorter.order.replace(/end$/, '')}`.toUpperCase()
    }
    location.query = _omitBy({
      ...location.query,
      p: pagination.current && `${pagination.current}`,
      sort
    }, _isEmpty)
  }
  const handleStartDateRangeChange = (_, [from, to]) => {
    setStartDateRange(from, to)
  }
  const setStartDateRange = (from, to) => {
    const { p, from: _from, to: _to, ...query } = location.query
    location.query = {
      ...query,
      ...(from && { from }),
      ...(to && { to }),
    }
  }
  const setReview = (symbol) => {
    const { p, r, ...query } = location.query
    location.query = {
      ...query,
      ...(symbol && { r: symbol }),
    }
  }
  const {
    currentPage: current,
    perPage: pageSize,
    itemCount: total
  } = data?.eventItems?.pageInfo ?? {}

  const registrationMethodContent = {
    'free': <Popover content={<>Queue = {intl.formatMessage({ id: 'app.queue', defaultMessage: 'Queue' })}</>} placement="right"><Tag color="red" icon={<CameraOutlined />}>Queue</Tag></Popover>,
    'auto': <Popover content={<>Lotto = {intl.formatMessage({ id: 'app.lotto', defaultMessage: 'Lotto' })}</>} placement="right"><Tag color="red" icon={<CameraOutlined />}>Lotto</Tag></Popover>,
    'manual': <Popover content={<>Custom = {intl.formatMessage({ id: 'app.custom', defaultMessage: 'Custom' })}</>} placement="right"><Tag color="red" icon={<CameraOutlined />}>Custom</Tag></Popover>
  };

  return (
    <MaintenanceMessage>
      <div className="filterTools" style={{ textAlign: 'right', padding: '6px' }}>
        <Form form={form} onFinish={handleSearch}>
          <Input.Group compact>
            <Form.Item name={['titleSearch']} initialValue={titleSearch}>
                <Input
                  placeholder={intl.formatMessage({ id: 'app.searchEvent', defaultMessage: 'Search Event' })}
                  style={{ width: 240 }}
                />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<SearchOutlined />}></Button>
            </Form.Item>
          </Input.Group>
        </Form>
      </div>
      <Radio.Group buttonStyle="solid" onChange={handleFilterChange}  style={{ margin: '1em' }}>
        <Radio.Button value="all"><FormattedMessage id="app.allEventsFilter" defaultMessage="All" /></Radio.Button>
        {!['broker', 'salesperson'].includes(user.role) && <Radio.Button value="register"><FormattedMessage id="app.regisReqEventsFilter" defaultMessage="Registration Required" /></Radio.Button>}
        {!['broker', 'salesperson'].includes(user.role) && <Radio.Button value="free"><FormattedMessage id="app.freeEventsFilter" defaultMessage="Free" /></Radio.Button>}
        {!['broker', 'salesperson'].includes(user.role) && <Radio.Button value="myEvent"><FormattedMessage id="app.myEvent" defaultMessage="My Event" /></Radio.Button>}
      </Radio.Group>
      <Table
        rowKey="_id"
        dataSource={data?.eventItems?.items}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: true }}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          current,
          pageSize,
          total,
        }}
      >
        <Table.Column
          title={<FormattedMessage id="app.eventTitle" defaultMessage="Event Title" />}
          key='title'
          dataIndex={['title']}
          render={(title, { _id, officialPartner, registrationMethod }) => {
            const $content = <>
              {['superadmin', 'admin'].includes(user.role) ? <div><Link to={`/events/${_id}/view`}>{title}</Link>&nbsp;</div> : <div>{title}&nbsp;</div>}
              {/* {title}&nbsp; */}
              {officialPartner && <Tag color="orange">Partner</Tag>}
              {registrationMethodContent[registrationMethod]}
            </>
            if (['superadmin', 'admin', 'operation_admin'].includes(user.role)) {
              return <Popover content={_id }>
              {$content}
            </Popover>
            }
            return $content
          }}
        />
        <Table.Column
          title={<FormattedMessage id="app.registrationStartsAt" defaultMessage="Registration Starts" />}
          key='registrationStartsAt'
          dataIndex={['registrationStartsAt']}
          render={(text, item) => item.registrationStartsAt && moment(item.registrationStartsAt).format('Do MMM YYYY, HH:mm:ss')}
        />
        <Table.Column
          title={<FormattedMessage id="app.registrationEndsAt" defaultMessage="Registration Ends" />}
          key='registrationEndsAt'
          dataIndex={['registrationEndsAt']}
          render={(text, item) => item.registrationEndsAt && moment(item.registrationEndsAt).format('Do MMM YYYY, HH:mm:ss')}
        />
        {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) &&
          <Table.Column
            title={<FormattedMessage id='app.photographerLimit' defaultMessage='Photographer Limit' />}
            key='photographerNumber'
            dataIndex={['photographerNumber']}
            render={(_id, item) =>
              item.photographerNumber !== null && (
                <p>
                  {item.registrationMethod === 'free'
                    ? `${item?.approvedUploadCount ?? 0}/${item.photographerNumber}`
                    : item.registrationMethod === 'auto'
                    ? `${item?.uploadCount ?? 0}/${item.photographerNumber}`
                    : ``}
                </p>
              )
            }
          />
        }
        <Table.Column
          sorter
          filterDropdown={<Space.Compact block>
            <DatePicker.RangePicker value={[from, to]} onChange={handleStartDateRangeChange} />
            <Button onClick={() => setStartDateRange(null, null)}><FormattedMessage id="app.reset" defaultMessage="Reset" /></Button>
          </Space.Compact>}
          // defaultSortOrder='descend'
          title={<FormattedMessage id="app.eventDate" defaultMessage="Event Date" />}
          key='startDate'
          dataIndex={['startDate']}
          render={(text, item) => moment(item.startDate).format('Do MMM YYYY')}
        />
        <Table.Column
          title={<FormattedMessage id="app.upload" defaultMessage="Upload" />}
          key='upload'
          dataIndex={['_id']}
          render={(_id, item) => {
            if (user.role === 'customerService') return null
            if (item.allowedPhotographerIds && item.allowedPhotographerIds.length > 0 && !item.allowedPhotographerIds.includes(user._id)) {
              return <Button disabled icon={<WarningOutlined />}>
                <FormattedMessage id="app.uploadRequestRejected" defaultMessage="Upload Request Rejected" />
              </Button>
            }
            return (item.approved
              ? <div><Link to={`/events/${_id}/upload`}>
                {['superadmin', 'admin', 'operation_admin', 'acccounting', 'broker'].includes(user.role)
                  // admin actions
                  ? <Button icon={<TeamOutlined />}>
                    Manage Photographers
                  </Button>
                  // non admin actions
                  : (['free', 'auto', 'manual'].includes(item.registrationMethod)
                    // if need upload approval
                    ? (item.userUpload
                      ? <React.Fragment>
                        {item.userUpload.approved === true &&
                          <Button type="primary" icon={<UploadOutlined />}>
                            <FormattedMessage id="app.upload" defaultMessage="Upload" />
                          </Button>
                        }
                        {item.userUpload.approved === false &&
                          <Button icon={<WarningOutlined />}>
                            <FormattedMessage id="app.uploadRequestRejected" defaultMessage="Upload Request Rejected" />
                          </Button>
                        }
                        {item.userUpload.approved === null &&
                          <Tooltip
                            style={{ whiteSpace: 'pre-line' }}
                            // open={true}
                            title="รออนุมัติสิทธิ์ในการเข้าถ่ายภาพ และ/หรืออัปโหลดภาพเพื่อจำหน่าย หากท่านได้รับสิทธิ์ สถานะจะเปลี่ยนเป็น “อัปโหลด”"
                          >
                            <Button icon={<HourglassOutlined />}>
                              <FormattedMessage id="app.uploadRequestPending" defaultMessage="Upload Request Pending Approval" />
                            </Button>
                          </Tooltip>
                        }
                      </React.Fragment>
                      : (item.registrationMethod === 'free' && item.photographerNumber !== null
                      ? ((item.photographerNumber <= item.approvedUploadCount) ? <Button disabled={moment(item.registrationEndsAt) < moment() || moment(item.registrationStartsAt) > moment() || ['manual'].includes(item.registrationMethod)|| (item.photographerNumber <= item.approvedUploadCount)}>
                        Full
                      </Button>
                      : <Button icon={<EditOutlined />} disabled={moment(item.registrationEndsAt) < moment() || moment(item.registrationStartsAt) > moment() || ['manual'].includes(item.registrationMethod)}>
                      <FormattedMessage id="app.uploadRequest" defaultMessage="Register for upload request" />
                      </Button>)
                      : <Button icon={<EditOutlined />} disabled={moment(item.registrationEndsAt) < moment() || moment(item.registrationStartsAt) > moment() || ['manual'].includes(item.registrationMethod)}>
                          <FormattedMessage id="app.uploadRequest" defaultMessage="Register for upload request" />
                        </Button>)) 
                    // if doesn't need upload approval
                    : <Button type="primary" icon={<UploadOutlined />}>
                        <FormattedMessage id="app.uploadRequest" defaultMessage="uploadRequest" />
                      </Button>
                  )}
              </Link> {(['photographer'].includes(user.role) && item.photographerNumber !== null) && <React.Fragment>
                {item.registrationMethod === 'free' && <>({item.approvedUploadCount}/{item.photographerNumber})</>}
                {item.registrationMethod === 'auto' && <>({item.uploadCount}/{item.photographerNumber})</>}
                <Tooltip title="จำนวนช่างภาพที่สมัคร/จำนวนช่างภาพที่เปิดรับสมัคร">
                <InfoCircleOutlined />
                </Tooltip> 
                </React.Fragment>
                }
                
              </div>
              : (item.createdByUserId === user._id
                ? (item.approved === false
                  ? <Popover content={<>{item.approvalMessage} <Button><Link to={`/events/${item._id}/edit`}><FormattedMessage id="app.edit" defaultMessage="Edit" /></Link></Button></>}>
                    <Tag color="red"><CloseCircleOutlined /> <FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Tag> <QuestionCircleOutlined /> <FormattedMessage id="app.viewReason" defaultMessage="View Reason" />
                  </Popover>
                  : <Tag color="orange"><HourglassOutlined /> <FormattedMessage id="app.status.event.pendingReview" defaultMessage="Pending Review" /></Tag>
                )
                : <Button disabled icon={<HourglassOutlined />}>
                  <FormattedMessage id="app.status.event.pending" defaultMessage="Upload" />
                </Button>
              )
            )
          }}
        />
        <Table.Column
          title={<FormattedMessage id="app.photos" defaultMessage="Photos" />}
          key='photos'
          dataIndex={['_id']}
          render={(_id, {photoCount, photoCountAutoTagged}) =>
            <Link to={`/events/${_id}/photos`} >
              {photoCount === 0
                ? <Button icon={<PictureOutlined />}>
                  {/* <FormattedMessage id="app.eventPhotos" defaultMessage="Event Photos" /> */}
                </Button>
                : Math.ceil(photoCountAutoTagged / photoCount * 100) !== 100
                  ? <Button>
                    {Math.ceil(photoCountAutoTagged / photoCount * 100)}% Tagged
                  </Button>
                  : <Button icon={<PictureOutlined />}>
                    {/* <FormattedMessage id="app.eventPhotos" defaultMessage="Event Photos" /> */}
                  </Button>
              }
            </Link>
          }
        />
        {['admin','operation_admin', 'accounting'].includes(user.role) &&
          [
            <Table.Column
              title={<FormattedMessage id="app.edit" defaultMessage="Edit" />}
              key='edit'
              dataIndex={['_id']}
              render={(_id) => <Link to={`/events/${_id}/edit`}><Button icon={<EditOutlined />} /></Link>}
            />,
            // <Table.Column
            //   title={<FormattedMessage id="app.remove" defaultMessage="Remove" />}
            //   key='remove'
            //   dataIndex={['_id']}
            //   render={(_id, item) => <EventDeleteButton event={item} />}
            // />,
            <Table.Column
              title={<FormattedMessage id="app.approve" defaultMessage="Approve" />}
              key='approve'
              dataIndex={['_id']}
              filterDropdown={<Radio.Group value={reviewStatus}>
                <Radio.Button value={SYMBOL_PENDING} onClick={() => setReview(SYMBOL_PENDING)}><FormattedMessage id="app.pending" defaultMessage="Pending" /></Radio.Button>
                <Radio.Button value={SYMBOL_APPROVED} onClick={() => setReview(SYMBOL_APPROVED)}><FormattedMessage id="app.approved" defaultMessage="Approved" /></Radio.Button>
                <Radio.Button value={SYMBOL_REJECTED} onClick={() => setReview(SYMBOL_REJECTED)}><FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Radio.Button>
                <Radio.Button value={undefined} onClick={() => setReview()}><FormattedMessage id="app.all" defaultMessage="All" /></Radio.Button>
              </Radio.Group>}
              render={(_id, item ) => <EventApproveButton event={item} />}
            />
          ]
        }
        {['superadmin'].includes(user.role) &&
          [
            <Table.Column
              title={<FormattedMessage id="app.edit" defaultMessage="Edit" />}
              key='edit'
              dataIndex={['_id']}
              render={(_id) => <Link to={`/events/${_id}/edit`}><Button icon={<EditOutlined />} /></Link>}
            />,
            <Table.Column
              title={<FormattedMessage id="app.remove" defaultMessage="Remove" />}
              key='remove'
              dataIndex={['_id']}
              render={(_id, item) => <EventDeleteButton event={item} />}
            />,
            <Table.Column
              title={<FormattedMessage id="app.approve" defaultMessage="Approve" />}
              key='approve'
              dataIndex={['_id']}
              filterDropdown={<Radio.Group value={reviewStatus}>
                <Radio.Button value={SYMBOL_PENDING} onClick={() => setReview(SYMBOL_PENDING)}><FormattedMessage id="app.pending" defaultMessage="Pending" /></Radio.Button>
                <Radio.Button value={SYMBOL_APPROVED} onClick={() => setReview(SYMBOL_APPROVED)}><FormattedMessage id="app.approved" defaultMessage="Approved" /></Radio.Button>
                <Radio.Button value={SYMBOL_REJECTED} onClick={() => setReview(SYMBOL_REJECTED)}><FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Radio.Button>
                <Radio.Button value={undefined} onClick={() => setReview()}><FormattedMessage id="app.all" defaultMessage="All" /></Radio.Button>
              </Radio.Group>}
              render={(_id, item) => <EventApproveButton event={item} />}
            />
          ]
        }
      
      </Table>
    </MaintenanceMessage>
  )
})

export default Events
