import React, { useState } from 'react'
import { Button, Modal, message, DatePicker, Form, Upload, InputNumber } from 'antd'
import { useMutation } from '@apollo/client'
import createOrUpdateManualTransferMutation from '~/graphql/mutations/createOrUpdateManualTransfer.gql'
import organizerEventsQuery from '~/graphql/queries/organizerEvents.gql'
import CloudImageUploader from '~/components/CloudImageUploader'
import moment from 'moment-timezone'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const OrganizerTransferButton = ({ event }) => {
  const [form] = Form.useForm()
  const { getFieldsValue, resetFields } = form
  // const intl = useIntl()
  const [createOrUpdateManualTransfer] = useMutation(createOrUpdateManualTransferMutation)
  const [open, setOpen] = useState(false)
  const { organizerTransfer } = event
  const handleOk = async () => {
    const values = getFieldsValue(true)
    const { record: { completedAt: $completedAt, ...record } } = values
    const completedAt = $completedAt.startOf('day')
    try {
      await createOrUpdateManualTransfer({
        variables: {
          record: {
            completedAt,
            userId: event.organizerId,
            eventId: event._id,
            userRole: 'organizer',
            ...record
          }
        },
        refetchQueries: [{
          query: organizerEventsQuery,
          ...(event.organizerId && {
            variables: { organizerId: event.organizerId }
          })
        }]
      })
      message.success('success', 4)
    } catch (err) {
      message.error(err)
    } finally {
      resetFields()
      setOpen(false)
    }
  }
  const handleClick = () => setOpen(true)
  const handleRemoveSlip = async () => {
    const values = getFieldsValue(true)
    const { record } = values
    await createOrUpdateManualTransfer({
      variables: {
        record: {
          ...record,
          slipUrl: null
        }
      }
    })
    setOpen(false)
  }
  return <>
    {organizerTransfer?.slipUrl
      ? <Upload
        defaultFileList={[{ uid: organizerTransfer?.slipUrl, url: organizerTransfer?.slipUrl, name: 'ดาวน์โหลดสลิป' }]}
        showUploadList={{ showRemoveIcon: true, showDownloadIcon: true }}
        onRemove={handleRemoveSlip}
      />
      : <Button onClick={handleClick}>แนบหลักฐาน</Button>
    }
    <Modal
      title={event.title}
      open={open}
      closable
      // onCancel={(e) => console.log(e, e.button, e.target)}
      onOk={handleOk}
      onCancel={() => setOpen(false)}
      maskClosable
    >
      <Form form={form}>
        <Form.Item {...formItemLayout} label="ส่วนแบ่ง Organizer">
          {event.totalOrganizerShare?.toFixed(2)}
        </Form.Item>
        <Form.Item {...formItemLayout} name={['record', 'amount']} label="ยอดเงินโอน" initialValue={organizerTransfer?.amount ?? Number.parseFloat(event.totalOrganizerShare?.toFixed(2))} rules={[{ required: true, message: '' }]}>
          <InputNumber min={1} disabled={!!organizerTransfer?.completedAt} />
        </Form.Item>
        <Form.Item {...formItemLayout} name={['record', 'completedAt']} label="วันที่โอนเงิน" initialValue={organizerTransfer?.completedAt && moment(organizerTransfer?.completedAt)} rules={[{ required: true, message: '' }]}>
          <DatePicker
            disabledDate={current => current.isSameOrBefore(organizerTransfer?.shareEndsAt)}
            disabled={!!organizerTransfer?.completedAt}
          />
        </Form.Item>
        <Form.Item {...formItemLayout} name={['record', 'slipUrl']} label="แนบสลิป" rules={[{ required: true, message: '' }]}>
          <CloudImageUploader provider="gs" path="documents" accept=".jpg,.jpeg,.png" />
        </Form.Item>
      </Form>
    </Modal>
  </>
}

export default OrganizerTransferButton
