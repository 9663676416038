import React from 'react'
import { Button, Col, DatePicker, Input, Radio, Row, Space, Table, Upload } from 'antd'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import _omitBy from 'lodash/omitBy'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { compose } from 'recompose'
import { useQuery } from '@apollo/client'
import { PrinterOutlined } from '@ant-design/icons'
import ExportData from './ExportData'
import SalespersonInfo from './SalespersonInfo'
import SalespersonList from './SalespersonList'
import config from '../config'
import SalespersonTransferButton from '~/components/SalespersonTransferButton'
import SalespersonTransferRequestPrint from '~/components/SalespersonTransferRequestPrint'
import salespersonEventsQuery from '~/graphql/queries/salespersonEvents.gql'

const SalespersonEvents = compose(
  inject('user'),
  inject('router'),
  observer
)(({ match, user, router }) => {
  const intl = useIntl()
  const { location } = router
  const { salespersonId } = match.params
  const {
    s: search,
    from: _from,
    to: _to,
    sort,
    paid,
    pb: printSalespersonId,
    pe: printEventId
  } = router.location.query
  const from = _from && moment(_from)
  const to = _to && moment(_to)
  const isPaid = ['true', 'false'].includes(paid) ? JSON.parse(paid) : undefined
  // console.log(location.query)
  const handlePrint = ({ eventId, salespersonId }) => {
    location.query = {
      ...location.query,
      pe: eventId,
      pb: salespersonId
    }
  }

  const handlePrintClose = () => {
    location.query = _omitBy({
      ...location.query,
      pe: null,
      pb: null
    }, _isEmpty)
  }

  const handleSalespersonChange = (salespersonId) => {
    if (!salespersonId) {
      return router.push(`/events:balance:salesperson`)
    }
    router.push(`/events:balance:salesperson/${salespersonId}`)
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let sort
    if (sorter.order) {
      sort = `${sorter.columnKey}_${sorter.order.replace(/end$/, '')}`.toUpperCase()
    }
    location.query = _omitBy({
      ...location.query,
      p: pagination.current && `${pagination.current}`,
      sort
    }, _isEmpty)
  }

  const handleSearch = (search) => {
    location.query = {
      ...location.query,
      s: search
    }
  }

  const handleStartDateRangeChange = (_, [from, to]) => {
    setStartDateRange(from, to)
  }

  const setStartDateRange = (from, to) => {
    location.query = _omitBy({
      ...location.query,
      from,
      to
    }, _isEmpty)
    // TODO: close filter
  }

  const handlePaidStatus = (isPaid) => {
    location.query = _omitBy({
      ...location.query,
      paid: isPaid
    }, _isUndefined)
  }

  const { error, loading, data } = useQuery(salespersonEventsQuery, {
    variables: {
      limit: 1000,
      // role: 'salesperson',
      salespersonId: match.params.salespersonId,
      salespersonTransferExists: isPaid,
      sort,
      titleSearch: search,
      ...(from && to && {
        startDateRange: {
          gte: from?.toISOString(),
          lte: to?.toISOString()
        }
      }),
    }
  })

  if (loading) {
    return 'Loading...'
  }
  
  if (error) {
    return 'Error'
  }

  const handleExportData = (data) => (
    data?.events?.map((item) => {
      return {
        'Event Name': item.title,
        'Event Date': moment(item.startDate).format('DD/MM/YYYY'),
        'Share Days': item.shareEndsAt && moment(item.shareEndsAt).diff(item.startDate, 'days') + 1,
        'Paid Date': item.salespersonTransfer ? moment(item.salespersonTransfer.completedAt).format('DD/MM/YYYY') : '-',
        'Share Ends': item.shareEndsAt && moment(item.shareEndsAt).format('DD/MM/YYYY'),
        'Share Method': `${item.salespersonShare ?? ''}${!item.salespersonShareUnit ? '-' : ''}${item.salespersonShareUnit === 'percentage' ? '%' : ''} ${
          item.salespersonShareUnit === 'fixed' ? 'fixed' : ''
        }`,
        Payer: item.salespersonTransfer?.approver?.username ?? '-',
        [`Salesperson Received (${config.currency})`]: item.salespersonShareAmount ?? 0,
        Salesperson: item.salesperson?.name ?? '-',
        'Paid Amount': item.salespersonTransfer?.amount ?? '-',
        Status: item.salespersonTransfer ? 'paid' : 'unpaid',
      }
    })
  )
  const { events } = data ?? {}
  const salespersonLabel = salespersonId ? events[0]?.salesperson?.formalName || 'Unknown' : 'All Salespersons'
  const printData = events?.find(e => e._id === printEventId)

  return (<>
    <Row gutter={12}>
      <Col sm={24} md={12} lg={8} >
        <SalespersonList onChange={handleSalespersonChange} value={salespersonId} role={user.role} style={{ width: '100%' }} />
      </Col>
      <Col sm={24} md={12} lg={16}>
        <ExportData
          exportedData={handleExportData}
          fileName={`salesperson_events_export_${moment().format('YYYY_MM_DD')}.xlsx`}
          filter={{
            salespersonId: match.params.salespersonId,
            salespersonTransferExists: isPaid,
            sort,
            titleSearch: search,
            ...(from && to && {
              startDateRange: {
                gte: from?.toISOString(),
                lte: to?.toISOString()
              }
            }),
          }}
          lazyQuery={salespersonEventsQuery}
          sheetNames={[salespersonLabel]}
          style={{ margin: '0.3em 1em 1em 0' }}
        />
        <SalespersonInfo salespersonId={salespersonId} />
      </Col>
    </Row>
    <Table
      loading={loading}
      dataSource={events}
      rowKey={(record) => record._id}
      scroll={{ x: true }}
      onChange={handleTableChange}
      // footer={() => <Row type="flex">
      //   <Col xs={3}>
      //     <span>รายรับทั้งหมด</span>
      //   </Col>
      //   <Col xs={21} style={{ textAlign: 'right' }}>
      //     {`${sum?.length > 0 ? sum.reduce((accumulator, currentValue) => accumulator + currentValue).toFixed(2) : 0} บาท`}
      //   </Col>
      // </Row>
      // }
    >
      {/* <Table.Column
          title='No.'
          dataIndex={['number']}
          key='number'
          render={(text, record, index) => index + 1}
        /> */}
      <Table.Column
        title='ชื่อกิจกรรม'
        dataIndex={['title']}
        key='title'
        fixed='left'
        filterDropdown={({ confirm }) =>
          <Input.Search
            defaultValue={search}
            onSearch={value => {
              handleSearch(value)
              confirm()
            }}
          />
        }
      />
      {!salespersonId && <Table.Column
        title='โบรคเกอร์'
        dataIndex={['salesperson', 'name']}
        key='salesperson'
      />}
      <Table.Column
        title='วันที่จัดกิจกรรม'
        dataIndex={['startDate']}
        key='startDate'
        sorter
        filterDropdown={({ confirm }) => (
          <Space.Compact block>
            <DatePicker.RangePicker value={[from, to]} onChange={(...args) => { handleStartDateRangeChange(...args); confirm() }} />
            <Button onClick={() => { setStartDateRange(null, null); confirm() }}><FormattedMessage id="app.reset" defaultMessage="Reset" /></Button>
          </Space.Compact>
        )}
        // filterDropdown={<Space.Compact block>
        //   <DatePicker.RangePicker value={[from, to]} onChange={handleStartDateRangeChange} />
        //   <Button onClick={() => setStartDateRange(null, null)}><FormattedMessage id="app.reset" defaultMessage="Reset" /></Button>
        // </Space.Compact>}
        render={startDate => moment(startDate).format('ll')}
      />
      <Table.Column
        title={<>วันสิ้นสุด<br />การคำนวณส่วนแบ่ง</>}
        dataIndex={['shareEndsAt']}
        key='shareEndsAt'
        sorter
        render={(shareEndsAt, { startDate }) => shareEndsAt && <>
          {moment(shareEndsAt).format('ll')} <br />
          ({moment(shareEndsAt).diff(startDate, 'days') + 1} วัน)
        </>}
      />
      <Table.Column
        title={<>ส่วนแบ่ง<br />โบรคเกอร์</>}
        dataIndex={['salespersonShare']}
        key='salespersonShare'
        render={(salespersonShare, { salespersonShareUnit }) => salespersonShare && `${salespersonShare} ${salespersonShareUnit === 'fixed' ? 'บาท' : '%'}`}
      />
      <Table.Column
        title={<>รายรับ<br />Salesperson</>}
        dataIndex={['salespersonShareAmount']}
        key='salespersonShareAmount'
        render={(salespersonShareAmount) => `${salespersonShareAmount.toFixed(2)} บาท`}
      />
      <Table.Column
        title={<FormattedMessage id="app.soldPhoto" defaultMessage="Photo Sold" />}
        dataIndex={['photoSoldCountInSharePeriod']}
        key='photoSoldCountInSharePeriod'
        // render={(_id) => (
        //   <EventOrderStats eventId={_id} sharedOnly>
        //     {({ loading, itemCount }) =>
        //       loading ? <Spin /> : <div>{itemCount}</div>
        //     }
        //   </EventOrderStats>
        // )}
      />
      <Table.Column
        title={`${intl.formatMessage({ id: 'app.photoSold', defaultMessage: 'Photo Sold' })} ${intl.formatMessage({ id: 'app.days', defaultMessage: '{day} days' }, { day: 30 })}`}
        dataIndex={['totalShareAmountInSharePeriod']}
        key='totalShareAmountInSharePeriod'
        render={(totalShareAmountInSharePeriod) => intl.formatNumber(totalShareAmountInSharePeriod, { style: 'currency', currency: config.currency, minimumFractionDigits: 2, currencyDisplay: 'code' })}
      />
      {['superadmin', 'admin', 'accounting'].includes(user.role) && <Table.Column
        title={<>เอกสาร<br/>สรุปยอด</>}
        dataIndex={['_id']}
        key='salesDocument'
        render={(eventId, { salespersonId }) =>
          <Button icon={<PrinterOutlined />} loading={printEventId === eventId} disabled={printEventId === eventId} onClick={() => handlePrint({ eventId, salespersonId })} />
        }
      />}
      <Table.Column
        title='การโอนเงิน'
        dataIndex={['salespersonTransfer']}
        key='salespersonTransfer'
        width={200}
        filterDropdown={({ confirm }) => <Radio.Group value={isPaid} onChange={(e) => { handlePaidStatus(e.target.value); confirm() }}>
          <Radio.Button value={true} ><FormattedMessage id="app.paid" defaultMessage="Paid" /></Radio.Button>
          <Radio.Button value={false} ><FormattedMessage id="app.unpaid" defaultMessage="Unpaid" /></Radio.Button>
          <Radio.Button value={undefined} ><FormattedMessage id="app.all" defaultMessage="All" /></Radio.Button>
        </Radio.Group>}

        // render={(salespersonBankSlip, { _id }) => <CloudImageUploader provider="gs" path="documents" value={salespersonBankSlip} onChange={(value) => handleUpload(value, _id)} />}
        render={(salespersonTransfer, item) => <>
          {salespersonTransfer?.amount && <div>
            <FormattedNumber value={salespersonTransfer?.amount} style="currency" currency={config.currency} currencySign="standard" />{/* eslint-disable-line react/style-prop-object */}
            {' '}
            {salespersonTransfer?.completedAt && <>
              เมื่อ {moment(salespersonTransfer?.completedAt).format('ll')}
            </>}
          </div>}
          {salespersonTransfer?.approver?.username && <div>
            โดย {salespersonTransfer?.approver?.username}
          </div>}
          {['accounting'].includes(user.role)
            ? <SalespersonTransferButton event={item} />
            : (salespersonTransfer?.slipUrl && <Upload
              defaultFileList={[{ uid: salespersonTransfer?.slipUrl, url: salespersonTransfer?.slipUrl, name: 'ดาวน์โหลดสลิป' }]}
              showUploadList={{ showRemoveIcon: false, showDownloadIcon: true }}
            />)
          }
        </>
        }
      />
    </Table>
    {printSalespersonId && printData &&
      <SalespersonTransferRequestPrint salespersonId={printSalespersonId} data={printData} onClose={handlePrintClose} />
    }
    {/* <SalesForm {...salesDocumentData} /> */}
  </>)
})

export default SalespersonEvents
