import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import React, { useState } from 'react'
import adminUserNamesQuery from '../graphql/queries/adminUserNames.gql'
// import _escapeRegExp from 'lodash/escapeRegExp'
const OrganizerList = ({ onChange, value, role, style }) => {
  const [search, setSearch] = useState('')
  const { error, loading, data } = useQuery(adminUserNamesQuery, {
    variables: {
      _id: search ? undefined : value,
      role: 'organizer',
      search
    }
  })
  if (error) {
    return 'Error'
  }
  if(role === 'organizer') {
    return <div style={{fontSize: '1.2rem'}}>{data?.adminUserList?.items.find((user) => user._id === value).displayName}</div>
  }
  const organizer = data?.adminUserList?.items.find(o => o._id === value && o?.organizerData?.formalName && o?.organizerData?.companyName)
  return <>
    <Select
      showSearch
      loading={loading}
      filterOption={false}
      onSearch={value => value ? setSearch(value) : setSearch()}
      onChange={onChange}
      onBlur={() => setSearch()}
      onSelect={() => setSearch()}
      value={value || null}
      style={{ width: 360, ...style }}
    >
      {!search && <Select.Option key={'all'} value={null}>All Partners</Select.Option>}
      {data?.adminUserList?.items.map((user) => <Select.Option key={user._id} value={user._id}>{user.displayName}</Select.Option>)}
    </Select>
    <div style={{ margin: '.5em' }}>
      {organizer
        ? <>{organizer.organizerData.formalName} {organizer.organizerData.companyName && <>({organizer.organizerData.companyName})</>}</>
        : (role === 'admin' || role === 'superadmin' || role === 'operation_admin')
        ? <>{data?.adminUserList?.count} partners</>
        : <></>
      }
    </div>
  </>
}

export default OrganizerList
