import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import { inject } from 'mobx-react'
import { Table, Row, Col, Spin } from 'antd'
import { compose } from 'recompose'
import moment from 'moment'
import couponCodeQuery from '../graphql/queries/couponCode.gql'
import couponCodeTypeQuery from '../graphql/queries/couponCodeType.gql'
import CouponSelect from './CouponSelect'
import CouponTypeSelect from './CouponTypeSelect'
import ExportData from './ExportData'
import config from '../config'

const CouponUsedList = compose(inject('router'))(({ router, match }) => {
  const intl = useIntl()
  const couponTypeId = match?.params?.couponTypeId
  const couponId = match?.params?.couponId
  const isSkip = !(couponId && couponId !== 'all')

  const {
    error: couponCodeError,
    loading: couponCodeLoading,
    data: couponCodeData,
  } = useQuery(couponCodeQuery, {
    skip: isSkip,
    variables: {
      _id: couponId,
    },
    fetchPolicy: 'network-only',
  })
  const {
    error: couponCodeTypeError,
    loading: couponCodeTypeLoading,
    data: couponCodeTypeData,
  } = useQuery(couponCodeTypeQuery, {
    skip: !isSkip,
    variables: {
      _id: couponTypeId,
    },
    fetchPolicy: 'network-only',
  })

  const error = couponCodeError || couponCodeTypeError
  const loading = couponCodeLoading || couponCodeTypeLoading
  const data = couponCodeData?.couponCode ?? couponCodeTypeData?.couponCodeType
  const couponTypeName = data?.name ?? data?.couponCodeType?.name
  const couponData = data?.couponUsed ?? data?.orders ?? []

  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }

  const handleCouponTypeChange = (input) => router.push(`/coupon-types/${input ?? 'all'}/coupons`)
  const handleCouponChange = (input) => router.push(`/coupon-types/${couponTypeId}/coupons/${input ?? 'all'}`)
  const handleExportData = (_data) => {
    const data = isSkip ? _data?.couponCodeType?.couponUsed : _data?.couponCode?.orders
    const couponDiscountType = `${_data?.couponCode?.couponCodeType?.discount ?? _data?.couponCodeType?.discount} ${
      (_data?.couponCode?.couponCodeType?.discountType ?? _data?.couponCodeType?.discountType) === 'percentage' ? '%' : config.currency
    }`
    return data.map((item) => {
      return {
        [intl.formatMessage({ id: 'app.email', defaultMessage: 'Email' })]: item?.clientEmail,
        [intl.formatMessage({ id: 'app.orderCompletedDate', defaultMessage: 'Payment Date' })]: moment(item?.completedAt).format('DD-MM-YYYY'),
        [intl.formatMessage({ id: 'app.paymentID', defaultMessage: 'Payment ID' })]: item.paymentId,
        [intl.formatMessage({ id: 'app.eventID', defaultMessage: 'Event ID' })]: item.eventId,
        [intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })]: item.title,
        [intl.formatMessage({ id: 'app.amount', defaultMessage: 'Amount' })]: item?.amount,
        [intl.formatMessage({ id: 'app.discountType', defaultMessage: 'Discount type' })]: couponDiscountType,
        [intl.formatMessage({ id: 'app.discount', defaultMessage: 'Discount' })]: item?.discount,
        [intl.formatMessage({ id: 'app.payAmount', defaultMessage: 'Pay Amount' })]: item?.payAmount,
        [intl.formatMessage({ id: 'app.couponCode', defaultMessage: 'Coupon Code' })]: item?.couponCodes.map((el) => el.code).join('\n'),
        [intl.formatMessage({ id: 'app.paymentMethod', defaultMessage: 'Payment Method' })]: item?.method,
      }
    })
  }

  return (
    <>
      <Row>
        <Col xs={24} sm={11}>
          <FormattedMessage id='app.couponCodeTypeId' defaultMessage='Coupon Code type ID' />
          <span>: </span>
          <CouponTypeSelect onChange={handleCouponTypeChange} couponTypeId={couponTypeId} />
        </Col>

        <Col xs={24} sm={11}>
          <FormattedMessage id='app.couponCode' defaultMessage='Coupon Code' />
          <span>: </span>
          <CouponSelect onChange={handleCouponChange} couponTypeId={couponTypeId} couponId={couponId} />
        </Col>

        <Col xs={24} sm={2} align='right'>
          <ExportData
            exportedData={handleExportData}
            fileName={`used_${couponTypeName}_${moment().format('DD_MM_YYYY')}.xlsx`}
            filter={{ _id: isSkip ? couponTypeId : couponId }}
            lazyQuery={isSkip ? couponCodeTypeQuery : couponCodeQuery}
            sheetNames={[couponTypeName]}
            style={{ margin: '0.3em 1em 1em 0' }}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table rowKey='_id' scroll={{ x: true }} dataSource={couponData} loading={loading}>
            <Table.Column title={<FormattedMessage id='app.email' defaultMessage='Email' />} key='clientEmail' dataIndex={['clientEmail']} />

            <Table.Column
              title={<FormattedMessage id='app.orderCompletedDate' defaultMessage='Payment Date' />}
              key='Payment Date'
              dataIndex={['completedAt']}
              render={(date) => moment(date).format('DD-MM-YYYY')}
            />

            <Table.Column title={<FormattedMessage id='app.event' defaultMessage='Event' />} key='event' dataIndex={['event', 'title']} />

            <Table.Column title={<FormattedMessage id='app.amount' defaultMessage='Amount' />} key='amount' dataIndex={['amount']} />

            <Table.Column title={<FormattedMessage id='app.discount' defaultMessage='Discount' />} key='discount' dataIndex={['discount']} />

            <Table.Column title={<FormattedMessage id='app.payAmount' defaultMessage='Pay Amount' />} key='payAmount' dataIndex={['payAmount']} />

            <Table.Column title={<FormattedMessage id='app.paymentMethod' defaultMessage='Payment Method' />} key='method' dataIndex={['method']} />

            <Table.Column
              title={<FormattedMessage id='app.couponCode' defaultMessage='Coupon Code' />}
              key='couponCode'
              dataIndex={['couponCodes']}
              render={(couponCode) => couponCode.map((el) => el.code).join('\n')}
            />

            <Table.Column
              title={<FormattedMessage id='app.orderLink' defaultMessage='Order Link' />}
              key='orderLink'
              dataIndex={['orderLink']}
              render={(orderLink) => {
                return (
                  <a href={orderLink}>
                    <FormattedMessage id='app.link' defaultMessage='Link' />
                  </a>
                )
              }}
            />
          </Table>
        </Col>
      </Row>
    </>
  )
})

export default CouponUsedList
