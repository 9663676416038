import React, { useState } from 'react'
import { FormattedNumber, FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { inject } from 'mobx-react'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Form, Input, InputNumber, Modal, Select, Spin } from 'antd'
import { compose } from 'recompose'
// import _min from 'lodash/min'
import adminUserFinancialQuery from '~/graphql/queries/adminUserFinancial.gql'
import createTransferRequestMutation from '~/graphql/mutations/createTransferRequest.gql'
import AdminUserSelect from './AdminUserSelect'
import Toolbar from './Toolbar'
import config from '../config'

const FEE = config.transferFee
const MINIMUM_AMOUNT = config.minimumTransferRequestAmount
const MAXIMUM_AUTO_APPROVED_AMOUNT = 50000

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  }
}

const TransferRequestCreate = compose(
  inject('user'),
  inject('router')
)(({ router, user }) => {
  const [form] = Form.useForm()
  const { getFieldValue, resetFields } = form
  const intl = useIntl()
  const authorized = ['superadmin', 'admin'].includes(user.role)
  const [submitting, setSubmitting] = useState(false)
  const [role, setRole] = useState()
  // TODO: better ACL
  // TODO: handle non-authorized
  const [userId, setUserId] = useState(['broker', 'organizer', 'photographer'].includes(user.role) ? user._id : undefined)
  const { error, loading, data } = useQuery(adminUserFinancialQuery, {
    skip: !userId,
    variables: { _id: userId },
    fetchPolicy: 'network-only'
  })
  const [createTransferRequest] = useMutation(createTransferRequestMutation)
  const { adminUser } = data || {}
  console.log(adminUser)
  const maxAmount = ['superadmin'].includes(user.role)
    ? adminUser?.balance?.total
    : Math.min(adminUser?.balance?.total, MAXIMUM_AUTO_APPROVED_AMOUNT)
  const handleRoleChange = (role) => {
    setRole(role)
    setUserId()
  }
  const handleAdminUserSelect = (userId) => {
    setUserId(userId)
  }
  const handleConfirm = () => {
    const {
      record: { amount }
    } = getFieldValue()
    Modal.confirm({
      title: intl.formatMessage({ id: 'app.confirm', defaultMessage: 'Confirm' }),
      content: intl.formatMessage(
        {
          id: config.platform === 'heyjom' ? 'app.confirmTransferCreateHeyjom' : 'app.confirmTransferCreate',
        },
        {
          amount,
          fee: FEE,
          total: amount - FEE,
          num: ([amount]) => intl.formatNumber(Number(amount), { minimumAmount: 2, style: 'currency', currency: config.currency, currencyDisplay: 'code' }),
          b: (chunks) => <b>{chunks}</b>
        }
      ),
      onOk: handleSubmit,
      style: { whiteSpace: 'pre-wrap' }
    })
  }
  const handleSubmit = async () => {
    setSubmitting(true)
    const {
      record: { amount, note }
    } = getFieldValue()
    try {
      await createTransferRequest({
        variables: {
          userId,
          amount,
          note
        }
      })
      resetFields()
      setSubmitting(false)
      router.push('/transfers')
    } catch (err) {
      setSubmitting(false)
      console.error(err)
    }
    // TODO: redirect to /transfers
  }
  if (error) {
    return 'Error'
  }
  return (
    <div>
      <Toolbar
        left={
          <div>
            {authorized && (
              <Select placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />} onChange={handleRoleChange} value={role} style={{ width: 160 }}>
                {['broker', 'organizer', 'photographer'].map((el) => (<Select.Option key={el} value={el}>{el}</Select.Option>))}
              </Select>
            )}
            {role && authorized && <AdminUserSelect onChange={handleAdminUserSelect} value={userId} role={role} style={{ width: 300 }} />}
          </div>
        }
      />
      {loading ? (
        <Spin style={{ display: 'block', lineHeight: '100vh' }} />
      ) : !userId && authorized ? (
        <div>
          <FormattedMessage id='app.selectUser' defaultMessage='Please Select User!' />
        </div>
      ) : (
        (config.payoutProvider === 'opn' && !adminUser?.recipientId) ||
        (config.payoutProvider === 'manual' && !adminUser?.bankAccount?.number)
      ) ? (
          <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
            <FormattedMessage id='app.notice.noBankAccount' defaultMessage='No bank account linked, please link bank account' />
            &nbsp;
            <Link to='/profile/edit'>
              <FormattedMessage id='app.action.editAccount' defaultMessage='Edit Account' />
            </Link>
          </div>
        ) : (config.payoutProvider === 'opn' && (!adminUser?.recipient?.active || !adminUser?.recipient?.verified)) ? (
          <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
            <FormattedMessage
              id='app.notice.recipientNotActivated'
              defaultMessage='Your bank account is not yet verified or activated, please wait around 1 hour, if your account is still not verified after 1 hour, please contact admin@thai.run'
              values={{
                contactEmail: config.email
              }}
            />
          </div>
        ) : adminUser?.incompleteTransfers?.length > 0 ? (
          <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
            <FormattedMessage
              id='app.notice.incompleteTransfer'
              defaultMessage='You have an incomplete transfer request (pending approval or pending transfer), please wait wait until the request is reviewed and completed before creating a new withdrawal request.'
            />
          </div>
        ) : adminUser?.balance?.total < MINIMUM_AMOUNT ? (
          <div style={{ border: '1px solid gainsboro', padding: '1em', margin: '1em', borderRadius: '6px', textAlign: 'center' }}>
            <FormattedMessage
              id='app.notice.insufficientBalance'
              defaultMessage='Your balance is {amount}, you must have at least {minimumAmount} in order to create a withdrawal request.'
              values={{
                amount: <FormattedNumber value={adminUser.balance.total} minimumFractionDigits={2} style="currency" currency={config.currency} />, /* eslint-disable-line react/style-prop-object */
                minimumAmount: <FormattedNumber value={MINIMUM_AMOUNT} minimumFractionDigits={2} style="currency" currency={config.currency} /> /* eslint-disable-line react/style-prop-object */
              }}
            />
          </div>
        ) : (
          <Form form={form} onFinish={handleConfirm}>
            <Form.Item {...formItemLayout} label={<FormattedMessage id='app.myBalance' defaultMessage='My Balance' />}>
              <FormattedNumber value={adminUser.balance.total} minimumFractionDigits={2} />
            </Form.Item>
            <Form.Item {...formItemLayout} label={<FormattedMessage id='app.accountNumber' defaultMessage='My Balance' />}>
              {adminUser?.bankAccount?.number ?? ''}
            </Form.Item>
            <Form.Item {...formItemLayout} label={<FormattedMessage id='app.accountName' defaultMessage='My Balance' />}>
              {adminUser?.bankAccount?.name ?? ''}
            </Form.Item>
            <Form.Item {...formItemLayout} name={['record', 'amount']} label={<FormattedMessage id='app.amountRequested' defaultMessage='Amount' />} initialValue={maxAmount}>
              <InputNumber precision={2} min={MINIMUM_AMOUNT} max={maxAmount} />
            </Form.Item>
            <Form.Item {...formItemLayout} label={<FormattedMessage id='app.deductTransferFee' defaultMessage='Deduct Transfer Fee' />}>
              <FormattedNumber value={-FEE} minimumFractionDigits={2} style="currency" currency={config.currency} /> {/* eslint-disable-line react/style-prop-object */}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {() => <Form.Item {...formItemLayout} label={<FormattedMessage id='app.amountToReceive' defaultMessage='Amount to be received' tagName='strong' />}>
                <strong>
                  <FormattedNumber value={getFieldValue(['record', 'amount']) - FEE} minimumFractionDigits={2} style="currency" currency={config.currency} /> {/* eslint-disable-line react/style-prop-object */}
                </strong>
              </Form.Item>}
            </Form.Item>
            <Form.Item {...formItemLayout} name={['record', 'note']} label={<FormattedMessage id='app.note' defaultMessage='Note' />}>
              <Input.TextArea />
            </Form.Item>
            <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 18, offset: 3 } }} style={{ whiteSpace: 'pre-line' }}>
              <FormattedMessage
                id={config.platform === 'heyjom' ? 'app.remark.transferRequestHeyjom' : 'app.remark.transferRequest'}
                values={{
                  // commissionRate: adminUser.commissionRate * 100,
                  minimumAmount: <FormattedNumber value={MINIMUM_AMOUNT} style="currency" currency={config.currency} /> /* eslint-disable-line react/style-prop-object */,
                  transferFee: <FormattedNumber value={FEE} style="currency" currency={config.currency} /> /* eslint-disable-line react/style-prop-object */,
                  maximumAutoApprovedAmount: <FormattedNumber value={MAXIMUM_AUTO_APPROVED_AMOUNT} style="currency" currency={config.currency} /> /* eslint-disable-line react/style-prop-object */
                }}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 8, offset: 8 } }}>
              <Button type='primary' htmlType='submit' loading={submitting}>
                <FormattedMessage id='app.submitRequest' defaultMessage='Submit Request' />
              </Button>
            </Form.Item>
          </Form>
        )}
    </div>
  )
})

export default TransferRequestCreate
