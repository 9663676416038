import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Select, Divider } from 'antd'
import adminUserNamesQuery from '../graphql/queries/adminUserNames.gql'

const AdminUserSelect = React.forwardRef(({ mode = 'default', onChange, value, selectAll, role, skip = false, style }, meta) => {
  const [search, setSearch] = useState('')
  const { error, loading, data } = useQuery(adminUserNamesQuery, {
    skip,
    variables: {
      role,
      search,
      perPage: 100,
    },
  })
  const {
    error: selectedError,
    loading: selectedLoading,
    data: selectedData,
  } = useQuery(adminUserNamesQuery, {
    skip: !value || !value.length,
    variables: {
      _operators: { _id: { in: Array.isArray(value) ? value : [value] } },
    },
  })
  if (error || selectedError) {
    return 'Error'
  }
  const handleSelectAll = () => {
    const organizerIds = data?.adminUserList?.items?.map((item) => item._id)
    onChange(organizerIds)
  }
  const [selectedUser] = selectedData?.adminUserList?.items ?? []
  if (mode === 'default') {
    return (
      <Select
        loading={loading || selectedLoading}
        showSearch
        filterOption={false}
        placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}
        onChange={onChange}
        onSearch={(v) => setSearch(v)}
        onBlur={() => setSearch()}
        value={selectAll ? value || 'all' : value}
        style={style}
      >
        {selectAll && (
          <Select.Option key={'all'} value={'all'}>
            <FormattedMessage
              id={`app.all${role.charAt(0).toUpperCase() + role.slice(1)}s`}
              defaultMessage={`All ${role.charAt(0).toUpperCase() + role.slice(1)}s`}
            />
          </Select.Option>
        )}
        {!search && selectedUser && (
          <Select.Option key={selectedUser._id} value={selectedUser._id}>
            {selectedUser.displayName}
            {role === 'photographer' && <span style={{ color: 'grey' }}> @{selectedUser.username}</span>}
          </Select.Option>
        )}
        {data?.adminUserList?.items.map((user) => (
          <Select.Option key={user._id} value={user._id}>
            {user.displayName}
            {role === 'photographer' && <span style={{ color: 'grey' }}> @{user.username}</span>}
          </Select.Option>
        ))}
      </Select>
    )
  }
  if (mode === 'multiple') {
    return (
      <Select
        mode='multiple'
        loading={loading}
        allowClear
        showSearch
        filterOption={false}
        placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}
        onChange={onChange}
        onSearch={(v) => setSearch(v)}
        onBlur={() => setSearch()}
        value={value}
        style={style}
        maxTagCount={6}
        maxTagPlaceholder={() => '...'}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {selectAll && (
              <div>
                <Divider style={{ margin: '4px 8px' }} />
                <div onMouseDown={(e) => e.preventDefault()} onClick={handleSelectAll} style={{ padding: '4px 8px', cursor: 'pointer' }}>
                  <FormattedMessage
                    id={`app.all${role.charAt(0).toUpperCase() + role.slice(1)}s`}
                    defaultMessage={`All ${role.charAt(0).toUpperCase() + role.slice(1)}s`}
                  />
                  {` (${data?.adminUserList?.count ?? 0})`}
                </div>
              </div>
            )}
          </div>
        )}
      >
        {data?.adminUserList?.items.map((user) => (
          <Select.Option key={user._id} value={user._id}>
            {user.displayName}
          </Select.Option>
        ))}
      </Select>
    )
  }
})

export default AdminUserSelect
