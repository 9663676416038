import React, { useState, useEffect } from "react";
// import { withRouter } from 'react-router'
import {
  Table,
  Pagination,
  Tag,
  Input,
  Button,
  Popover,
  Affix,
  Checkbox,
  Col,
  Row,
  Space,
} from "antd";
import { BarsOutlined, DeleteOutlined, PictureOutlined } from '@ant-design/icons'
// import qs from 'querystring'
import _get from "lodash/get";
import _omitBy from "lodash/omitBy";
import _isEmpty from "lodash/isEmpty";
// import _isUndefined from "lodash/isUndefined";
import "./PhotoList.css";
import "./PhotoTag.css";
import { inject } from "mobx-react";
import { useQuery, useMutation } from "@apollo/client";
import MaintenanceMessage from "~/components/MaintenanceMessage";
import DownloadPhoto from "~/components/DownloadPhoto";
import FaceSearch from "~/components/FaceSearch";
import eventQuery from "~/graphql/queries/event.gql";
import photoItemsQuery from "~/graphql/queries/photoItems.gql";
import searchPhotosQuery from "~/graphql/queries/searchPhotos.gql";
import searchPhotosByFaceQuery from "~/graphql/queries/searchPhotosByFace.gql";
import deletePhotosMutation from "~/graphql/mutations/deletePhotos.gql";
import moment from "moment";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { compose } from "recompose";

const limit = 100;

const EventPhotos = compose(
  inject('router'),
  inject('user')
)(
  ({
    router,
    user,
    match: {
      params: { eventId },
    },
  }) => {
    // const history =useHistory()
    const { location } = router;

    const { s: bib, i: imageId, u, p: page } = location.query;
    const unidentified = parseInt(u, 10) === 0;
    // const mode = "image";
    // const {event}  = useState();
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [holdShift, setHoldShift] = useState(false);
    // const { imgUrl, setImgUrl } = useState("");
    // const { fileName, setFilename } = useState("");
    const { view, allowed } = router.location.query;
    const { ...query } = location.query;
    const { awsAccount } = router.location.query;

    const userId = ["superadmin", "admin", "accounting"].includes(user.role)
      ? _get(router, "location.query.userId")
      : user._id;
    const isBibSearch = !!bib
    const isFaceSearch = !!imageId
    const {
      // loading: eventLoading,
      // error: eventError,
      data: eventData,
    } = useQuery(eventQuery, {
      variables: { _id: eventId },
    });

    const {
      loading: searchPhotosLoading,
      error: searchPhotosError,
      data: searchPhotosData,
    } = useQuery(searchPhotosQuery, {
      variables: {
        eventId,
        photographerId: userId,
        searchText: bib,
        unidentified,
        page: parseInt(page, 10) || 1,
        publicUseAllowed: allowed && true,
      },
      skip: !isBibSearch,
    });

    const {
      loading: searchPhotosByFaceLoading,
      error: searchPhotosByFaceError,
      data: searchPhotosByFaceData,
    } = useQuery(searchPhotosByFaceQuery, {
      fetchPolicy: "network-only",
      variables: {
        eventId,
        photographerId: userId,
        imageId,
      },
      skip: !isFaceSearch,
    });

    const {
      loading: photoItemsLoading,
      error: photoItemsError,
      data: photoItemsData,
    } = useQuery(photoItemsQuery, {
      fetchPolicy: "network-only",
      variables: {
        eventId,
        photographerId: userId,
        // unidentified,
        page: parseInt(page, 10) || 1
        // publicUseAllowed: allowed && true,
      },
      skip: isBibSearch || isFaceSearch,
    });

    const [deletePhotos] = useMutation(deletePhotosMutation)

    useEffect(() => {
      document.addEventListener('keydown', _keyDown)
      document.addEventListener('keyup', _keyUp)
      return () => {
        document.removeEventListener('keydown', _keyDown)
        document.addEventListener('keyup', _keyUp)
      }
    }, []);

    const _keyDown = (event) => {
      if (event.keyCode === 16) {
        setHoldShift(true);
      }
    };

    const _keyUp = (event) => {
      if (event.keyCode === 16) {
        setHoldShift(false);
      }
    };

    const onPageChange = (page, pageSize) => {
      location.query = {
        ...location.query,
        p: page,
      };
      // this.props.onPageChange(page)
    };
    const onSelectChange = (selectedPhotos) => {
      setSelectedPhotos(selectedPhotos);
    };

    const isSelectedPhoto = (id) => {
      // console.log("is selected", id, typeof id, selectedPhotos);
      if (selectedPhotos.indexOf(id) < 0) {
        return false;
      } else {
        return true;
      }
    };

    const onSelectPhoto = (id) => {
      // console.log('select', selectedPhotos,id, typeof id)
      if (!isSelectedPhoto(id)) {
        selectedPhotos.push(id);
        setSelectedPhotos([...selectedPhotos]);
      } else {
        let position = selectedPhotos.indexOf(id);
        selectedPhotos.splice(position, 1);
        setSelectedPhotos([...selectedPhotos]);
      }
    };

    const _multiSelect = (id) => {
      if (holdShift) {
        onSelectPhoto(id);
      } else {
        router.push(`/photos/${id}/edit`);
      }
    };

    const rowSelection = {
      selectedRowKeys: selectedPhotos,
      onChange: onSelectChange,
    };

    const handleAllowedFilter = (e) => {
      location.query = {
        ...(e.target.checked && { allowed: e.target.checked }),
        ...query,
      };
    };

    const handleFaceInput = (imageId) => {
      console.log("handleFaceInput", { imageId });
      location.query = _omitBy(
        {
          // test: 'xxx',
          ...location.query,
          s: undefined,
          p: undefined,
          u: undefined,
          i: imageId,
        },
        _isEmpty
      );
      window.location.reload();
    };

    const handleClearFace = () => {
      location.query = _omitBy(
        {
          // test: 'xxx',
          ...location.query,
          // s: undefined,
          p: undefined,
          u: undefined,
          i: undefined,
        },
        _isEmpty
      );
    };

    const handleSearch = (values) => {
      location.query = _omitBy(
        {
          ...location.query,
          s: values,
        },
        _isEmpty
      );
    };

    const handleDeletePhotos = () => {
      // console.log("Delte : " + this.state.selectedPhotos)
      deletePhotos({
        variables: { _ids: selectedPhotos },
        // refetchQueries: [{
        //   query: photoItems
        // }]
      }).then(() => console.log("Delete Success!"));
    };

    const changeViewMode = (mode) => {
      location.query = {
        ...location.query,
        view: mode,
      };
    };

    if (searchPhotosLoading || searchPhotosByFaceLoading || photoItemsLoading) {
      return "Load...";
    }
    if (searchPhotosError || searchPhotosByFaceError || photoItemsError) {
      return "Error...";
    }

    const photoItems = photoItemsData?.photoItems
    const searchPhotosByBib = searchPhotosData?.searchPhotos
    const searchPhotosByFace = searchPhotosByFaceData?.searchPhotosByFace

    const searchPhotos = photoItems ?? searchPhotosByBib ?? searchPhotosByFace
    const searchItems = searchPhotos?.items
    const photosCount = searchPhotos?.count
    const pageInfo = searchPhotos?.pageInfo 
    
    const {
      currentPage,
      itemCount,
      perPage
    } = pageInfo ?? {}

    const  pageSize = perPage ?? limit

    if (!searchItems) {
      return <div>no result</div>;
    }

    const photos = searchItems;
    // const taggedPhotoCount = photos?.length;
    const event = eventData?.event;
    const photoTagText = event?.photoTagText;
    const photoTagFaces = event?.photoTagFaces;

    if (view === "image") {
      return (
        <MaintenanceMessage>
             <div>
              <Row >
              <Col span={8}>
                  <p style={{ marginLeft: "32px" }}>
                    {event?.title} |วันที่จัด:{" "}
                    {moment(event?.startDate).format("LL")}
                  </p>
                </Col>
                {['superadmin', 'admin', 'operation_admin'].includes(user.role) && <Col span={10}>
                  <div>
                    {photoTagText ? (
                      <Col span={8}>
                        <Row>
                          <Col>
                            <Input.Search
                              enterButton
                              allowClear
                              onSearch={handleSearch}
                            />
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      <div></div>
                    )}
                    {photoTagFaces ? (
                      <Col span={6} style={{ paddingLeft: "10px" }}>
                        <FaceSearch
                          onInput={handleFaceInput}
                          onClear={handleClearFace}
                          imageId={imageId}
                          awsAccount={awsAccount}
                        />
                      </Col>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </Col>}
              </Row>
              </div>
          <div>
            <Affix>
              <div style={{ float: "right" }}>
                <Space.Compact block style={{ paddingRight: "10px" }}>
                  <Button
                    type="submit"
                    disabled={selectedPhotos.length === 0}
                    onClick={handleDeletePhotos}
                  >
                    <span>
                      <FormattedMessage
                        id="app.deletePhotos"
                        defaultMessage="Delete Photos"
                      />{" "}
                      <DeleteOutlined />
                    </span>
                  </Button>
                </Space.Compact>
                <Space.Compact block>
                  <Button
                    type={view === "image" ? "primary" : "default"}
                    onClick={() => changeViewMode("image")}
                  >
                    <span>
                      <PictureOutlined />{" "}
                      <FormattedMessage
                        id="app.images"
                        defaultMessage="Images"
                      />
                    </span>
                  </Button>
                  <Button
                    type={view === "table" ? "primary" : "default"}
                    onClick={() => changeViewMode("table")}
                  >
                    <span>
                      <BarsOutlined />{" "}
                      <FormattedMessage id="app.list" defaultMessage="List" />
                    </span>
                  </Button>
                </Space.Compact>
              </div>
             
              <div style={{ textAlign: "center" }}></div>
            </Affix>
            <br />
            <p style={{ marginLeft: "32px" }}>
              Photo Summary: Uploaded <FormattedNumber value={photosCount} />
              {/* |{" "} */}
              {/* <FormattedMessage id="app.tagged" defaultMessage="Tagged" />
              <FormattedNumber value={taggedPhotoCount} /> */}
            </p>

            <div>
              <div className="photos">
                {photos.map((photo) => (
                  <div className="photo" key={photo._id}>
                    <div className="image">
                      <img
                        src={_get(photo, "view.thumbnail.url", "")}
                        alt=""
                        onClick={() => _multiSelect(photo._id)}
                      />
                      <Checkbox
                        onChange={() => onSelectPhoto(photo._id)}
                        checked={isSelectedPhoto(photo._id)}
                        style={{
                          float: "right",
                          paddingTop: "2px",
                          paddingRight: "2px",
                          display: "block"
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Affix offsetBottom={0}>
              <Pagination
                showQuickJumper
                pageSize={pageSize}
                current={currentPage}
                total={itemCount}
                onChange={onPageChange}
                style={{
                  background: "rgba(255, 255, 255, 0.8)",
                  padding: "6px",
                  textAlign: "center",
                }}
              />
            </Affix>
          </div>
        </MaintenanceMessage>
      );
    } else {
      const columns = [
        {
          title: (
            <FormattedMessage id="app.fileName" defaultMessage="File Name" />
          ),
          // dataIndex: ["fileName"],
          key: "fileName",
          render: (text, photo) => (
            <a href={`/photos/${photo._id}/edit`}>
              {photo.fileName}
            </a>
          ),
        },
        {
          title: (
            <FormattedMessage id="app.dimensions" defaultMessage="Dimensions" />
          ),
          // dataIndex: ['size'],
          key: "size",
          render: (text, photo) => (
            <Tag>
              {_get(photo, "original.size.width") &&
                _get(photo, "original.size.height") &&
                `${_get(photo, "original.size.width")} x ${_get(
                  photo,
                  "original.size.height"
                )}`}
            </Tag>
          ),
        },
        {
          title: <FormattedMessage id="app.status" defaultMessage="Status" />,
          // dataIndex: ['size'],
          key: "status",
          render: (text, photo) => (
            <span>
              {_get(photo, "meta.tagged") ? (
                <FormattedMessage id="app.tagged" defaultMessage="Tagged" />
              ) : _get(photo, "processed") ? (
                <FormattedMessage id="app.tagging" defaultMessage="Tagging" />
              ) : (
                <FormattedMessage
                  id="app.applyingWatermark"
                  defaultMessage="Applying Watermark"
                />
              )}
            </span>
          ),
        },
        {
          title: <FormattedMessage id="app.preview" defaultMessage="Preview" />,
          key: "preview",
          render: (text, photo) => (
            <Popover
              content={
                <img src={_get(photo, "view.thumbnail.url", "")} alt="" />
              }
            >
              <Button>
                <FormattedMessage id="app.preview" defaultMessage="Preview" />
              </Button>
            </Popover>
          ),
        },
        {
          title: (
            <FormattedMessage id="app.download" defaultMessage="Download" />
          ),
          key: "download",
          render: (text, photo) => (
            <DownloadPhoto
              _id={_get(photo, "_id", "")}
              allowed={['superadmin', 'admin'].includes(user.role) || _get(photo, 'publicUseAllowed', '')}
            />
          ),
        },
        {
          title: "Allowed On",
          key: "allowedAt",
          dataIndex: ["publicUseAllowedAt"],
          render: (text) => (text ? moment(text).format("LL") : ""),
        },
      ];
      return (
        <MaintenanceMessage>
          <div>
            <Affix>
              <div style={{ float: "right" }}>
                <Checkbox
                  onChange={handleAllowedFilter}
                  checked={router.location.query.allowed}
                >
                  Allowed photos only
                </Checkbox>
                <Space.Compact block style={{ paddingRight: "10px" }}>
                  <Button
                    type="submit"
                    disabled={selectedPhotos.length === 0}
                    onClick={handleDeletePhotos}
                  >
                    <span>
                      <FormattedMessage
                        id="app.deletePhotos"
                        defaultMessage="Delete Photos"
                      />{" "}
                      <DeleteOutlined />
                    </span>
                  </Button>
                </Space.Compact>
                <Space.Compact block>
                  <Button
                    type={view === "image" ? "primary" : "default"}
                    onClick={() => changeViewMode("image")}
                  >
                    <span>
                      <PictureOutlined />{" "}
                      <FormattedMessage
                        id="app.images"
                        defaultMessage="Images"
                      />
                    </span>
                  </Button>
                  <Button
                    type={view === "table" ? "primary" : "default"}
                    onClick={() => changeViewMode("table")}
                  >
                    <span>
                      <BarsOutlined />{" "}
                      <FormattedMessage id="app.list" defaultMessage="List" />
                    </span>
                  </Button>
                </Space.Compact>
              </div>
            </Affix>
            <br />
            <p style={{ marginLeft: "32px" }}>
              Photo Summary: Uploaded <FormattedNumber value={photosCount} />
              {/* |{" "} */}
              {/* <FormattedMessage id="app.tagged" defaultMessage="Tagged" />
              <FormattedNumber value={taggedPhotoCount} /> */}
            </p>
            <div className="photos">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={photos.filter((p) => !p.deleted)}
                rowKey={(photo) => photo._id}
                pagination={false}
              />
            </div>
            <Affix offsetBottom={0}>
              <Pagination
                showQuickJumper
                pageSize={perPage}
                current={currentPage}
                total={itemCount}
                onChange={onPageChange}
                style={{
                  background: "rgba(255, 255, 255, 0.8)",
                  padding: "6px",
                  textAlign: "center",
                }}
              />
            </Affix>
          </div>
        </MaintenanceMessage>
      );
    }
  }
);
export default EventPhotos;
