import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import React, { useState } from 'react'
import adminUserNamesQuery from '../graphql/queries/adminUserNames.gql'
// import _escapeRegExp from 'lodash/escapeRegExp'
const BrokerList = ({ onChange, value, role, style }) => {
  const [search, setSearch] = useState('')
  const { error, loading, data } = useQuery(adminUserNamesQuery, {
    variables: {
      _id: search ? undefined : value,
      role: 'broker',
      search
    }
  })
  if (error) {
    return 'Error'
  }
  if(role === 'broker') {
    return <div style={{fontSize: '1.2rem'}}>{data?.adminUserList?.items.find((user) => user._id === value).displayName}</div>
  }
  const broker = data?.adminUserList?.items?.find(o => o._id === value && o?.brokerData?.formalName)
  return <>
    <Select
      showSearch
      loading={loading}
      filterOption={false}
      onSearch={value => value ? setSearch(value) : setSearch()}
      onChange={onChange}
      onBlur={() => setSearch()}
      onSelect={() => setSearch()}
      value={value || null}
      style={{ width: 360, ...style }}
    >
      {!search && <Select.Option key={'all'} value={null}>All Brokers</Select.Option>}
      {data?.adminUserList?.items.map((user) => <Select.Option key={user._id} value={user._id}>{user.displayName}</Select.Option>)}
    </Select>
    <div style={{ margin: '.5em' }}>
      {broker
        ? broker.brokerData.formalName
        :['superadmin', 'admin', 'operation_admin',].includes(role)
        // : (role === 'admin' )
        ? <>{data?.adminUserList?.count} brokers</>
        : <></>
      }
    </div>
  </>
}

export default BrokerList
