import React from 'react'
import { Table, Button, Row, Col, Input, DatePicker, Space } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import moment from 'moment'
import adminUserQuery from '~/graphql/queries/adminUser.gql'
// import photographerEventsQuery from '~/graphql/queries/photographerEvents.gql'
import { useQuery } from '@apollo/client'
// import SalespersonTransferButton from '~/components/SalespersonTransferButton'
// import SalespersonTransferRequestPrint from '~/components/SalespersonTransferRequestPrint'
import { inject } from 'mobx-react'
import PhotographerList from './PhotographerList'
import _omitBy from 'lodash/omitBy'
import _isEmpty from 'lodash/isEmpty'
// import _isUndefined from 'lodash/isUndefined'
// import _isBoolean from 'lodash/isBoolean'
import { FormattedMessage } from 'react-intl'
// import SalespersonInfo from './SalespersonInfo'
import { compose } from 'recompose'
// import EventOrderStats from './EventOrderStats'
import * as XLSX from 'xlsx'
import { observer } from 'mobx-react-lite'
// import config from '../config'

const PhotographerEvents = compose(
  inject('user'),
  inject('router'),
  observer
)(({ match, user, router }) => {
  // const intl = useIntl()
  const { location } = router
  const { photographerId } = match.params
  const {
    s: search,
    from: _from,
    to: _to,
    // sort,
    // paid,
    // pb: printphotographerId,
    // pe: printEventId
  } = router.location.query
  const from = _from && moment(_from)
  const to = _to && moment(_to)
  // const isPaid = ['true', 'false'].includes(paid) ? JSON.parse(paid) : undefined
  // console.log(location.query)
  // const handlePrint = ({ eventId, photographerId }) => {
  //   location.query = {
  //     ...location.query,
  //     pe: eventId,
  //     pb: photographerId
  //   }
  // }

  // const handlePrintClose = () => {
  //   location.query = _omitBy({
  //     ...location.query,
  //     pe: null,
  //     pb: null
  //   }, _isEmpty)
  // }

  const handlePhotographerChange = (photographerId) => {
    if (!photographerId) {
      return router.push(`/events:balance:photographer`)
    }
    router.push(`/events:balance:photographer/${photographerId}`)
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let sort
    if (sorter.order) {
      sort = `${sorter.columnKey}_${sorter.order.replace(/end$/, '')}`.toUpperCase()
    }
    location.query = _omitBy({
      ...location.query,
      p: pagination.current && `${pagination.current}`,
      sort
    }, _isEmpty)
  }

  const handleSearch = (search) => {
    location.query = {
      ...location.query,
      s: search
    }
  }

  const handleStartDateRangeChange = (_, [from, to]) => {
    setStartDateRange(from, to)
  }

  const setStartDateRange = (from, to) => {
    location.query = _omitBy({
      ...location.query,
      from,
      to
    }, _isEmpty)
    // TODO: close filter
  }

  // const handlePaidStatus = (isPaid) => {
  //   location.query = _omitBy({
  //     ...location.query,
  //     paid: isPaid
  //   }, _isUndefined)
  // }

  const { error, loading, data } = useQuery(adminUserQuery, {
    skip: !photographerId,
    variables: {
      id: photographerId,
    },
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return 'Loading...'
  }
  
  if (error) {
    return 'Error'
  }

  const exportToCSV = (apiData, fileName = 'unknown.xlsx', SheetNames) => {
    const ws = apiData.map(item => XLSX.utils.json_to_sheet(item))
    const Sheets = {}
    SheetNames.forEach((item, index) => Sheets[item] = ws[index])
    const wb = { Sheets, SheetNames }
    XLSX.writeFile(wb, fileName)
  }

  const exportData = data?.adminUser?.events?.map((item) => {
    return {
      'Event Name': item.title,
      'Event Date': moment(item.startDate).format('DD/MM/YYYY'),
      'PhotographerShare': item.photographerShare ?? '-',
      'PhotographerShare Amount': item.photographerShareAmount ?? '-',
      'AccessibleOrderCount': item.accessibleOrderCount ?? '-',
      'Photo Count': item.photoCount ?? '-',
      'orderPhotoCount': item.orderPhotoCount ?? '-',
    }
  })
  const { events } = data?.adminUser ?? {}
  const salespersonLabel = photographerId ? events[0]?.photographers?.username || 'Unknown' : 'All Salespersons'
  // const printData = events?.find(e => e._id === printEventId)

  return (<>
    <Row gutter={12}>
      <Col sm={24} md={12} lg={8} >
        <PhotographerList onChange={handlePhotographerChange} value={photographerId} role={user.role} style={{ width: '100%' }} />
      </Col>
      <Col sm={24} md={12} lg={16} >
        {exportData && (
          <Button
            icon={<FileExcelOutlined />}
            type='primary'
            size='small'
            onClick={() => exportToCSV([exportData], `salesperson_events_export_${moment().format('YYYY_MM_DD')}.xlsx`, [salespersonLabel])}
            style={{ position: 'absolute', right: 0, zIndex: 1 }}
          >
            <FormattedMessage id='app.export' defaultMessage='Export' />
          </Button>
        )}
        {/* <SalespersonInfo salespersonId={salespersonId} /> */}
      </Col>
    </Row>
    <Table
      loading={loading}
      dataSource={events}
      rowKey={(record) => record._id}
      scroll={{ x: true }}
      onChange={handleTableChange}
      // footer={() => <Row type="flex">
      //   <Col xs={3}>
      //     <span>รายรับทั้งหมด</span>
      //   </Col>
      //   <Col xs={21} style={{ textAlign: 'right' }}>
      //     {`${sum?.length > 0 ? sum.reduce((accumulator, currentValue) => accumulator + currentValue).toFixed(2) : 0} บาท`}
      //   </Col>
      // </Row>
      // }
    >
      {/* <Table.Column
          title='No.'
          dataIndex={['number']}
          key='number'
          render={(text, record, index) => index + 1}
        /> */}
      <Table.Column
        title='ชื่อกิจกรรม'
        dataIndex={['title']}
        key='title'
        fixed='left'
        filterDropdown={({ confirm }) =>
          <Input.Search
            defaultValue={search}
            onSearch={value => {
              handleSearch(value)
              confirm()
            }}
          />
        }
      />
      {!photographerId && <Table.Column
        title='photographer'
        dataIndex={['photographer', 'displayName']}
        key='photographer'
      />}
      <Table.Column
        title='วันที่จัดกิจกรรม'
        dataIndex={['startDate']}
        key='startDate'
        sorter
        filterDropdown={({ confirm }) => (
          <Space.Compact block>
            <DatePicker.RangePicker value={[from, to]} onChange={(...args) => { handleStartDateRangeChange(...args); confirm() }} />
            <Button onClick={() => { setStartDateRange(null, null); confirm() }}><FormattedMessage id="app.reset" defaultMessage="Reset" /></Button>
          </Space.Compact>
        )}
        // filterDropdown={<Space.Compact block>
        //   <DatePicker.RangePicker value={[from, to]} onChange={handleStartDateRangeChange} />
        //   <Button onClick={() => setStartDateRange(null, null)}><FormattedMessage id="app.reset" defaultMessage="Reset" /></Button>
        // </Space.Compact>}
        render={startDate => moment(startDate).format('ll')}
      />
      {/* <Table.Column
        title={<>วันสิ้นสุด<br />การคำนวณส่วนแบ่ง</>}
        dataIndex={['shareEndsAt']}
        key='shareEndsAt'
        sorter
        render={(shareEndsAt, { startDate }) => shareEndsAt && <>
          {moment(shareEndsAt).format('ll')} <br />
          ({moment(shareEndsAt).diff(startDate, 'days') + 1} วัน)
        </>}
      /> */}
      <Table.Column
        title={<>ส่วนแบ่ง<br />Photographer</>}
        dataIndex={['photographerShare']}
        key='photographerShare'
        render={(photographerShare, { photographerShareUnit }) => photographerShare && `${photographerShare} ${photographerShareUnit === 'fixed' ? 'บาท' : '%'}`}
      />
      <Table.Column
        title={<>รายรับ<br />Photographer</>}
        dataIndex={['photographerShareAmount']}
        key='photographerShareAmount'
        render={(photographerShareAmount) => `${photographerShareAmount.toFixed(2)} บาท`}
      />
      <Table.Column
        title={<FormattedMessage id="app.soldPhoto" defaultMessage="Photo Sold" />}
        dataIndex={['accessiblePhotoSoldCount']}
        key='accessiblePhotoSoldCount'
        // render={(_id) => (
        //   <EventOrderStats eventId={_id} sharedOnly>
        //     {({ loading, itemCount }) =>
        //       loading ? <Spin /> : <div>{itemCount}</div>
        //     }
        //   </EventOrderStats>
        // )}
      />
      <Table.Column
        title={<>จำนวนรูปที่อัปโหลด<br />Photographer</>}
        dataIndex={['photoCount']}
        key='photoCount'
        // render={(photographerShareAmount) => `${photographerShareAmount.toFixed(2)} บาท`}
      />
      <Table.Column
        title={<>จำนวนรูปที่ขายได้<br />Photographer</>}
        dataIndex={['orderPhotoCount']}
        key='orderPhotoCount'
        // render={(photographerShareAmount) => `${photographerShareAmount.toFixed(2)} บาท`}
      />
      {/* <Table.Column
        title={<FormattedMessage id="app.totalSold" defaultMessage="Total Sold" />}
        dataIndex={['totalShareAmountInSharePeriod']}
        key='totalShareAmountInSharePeriod'
        render={(totalShareAmountInSharePeriod) => `${intl.formatNumber(totalShareAmountInSharePeriod, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${intl.formatMessage({ id: 'app.baht', defaultMessage: 'Baht' })}`}
      />
      {['superadmin', 'admin', 'accounting'].includes(user.role) && <Table.Column
        title={<>เอกสาร<br/>สรุปยอด</>}
        dataIndex={['_id']}
        key='salesDocument'
        render={(eventId, { salespersonId }) =>
          <Button icon={<PrinterOutlined />} loading={printEventId === eventId} disabled={printEventId === eventId} onClick={() => handlePrint({ eventId, salespersonId })} />
        }
      />} */}

    </Table>
    {/* {printSalespersonId && printData &&
      <SalespersonTransferRequestPrint salespersonId={printSalespersonId} data={printData} onClose={handlePrintClose} />
    } */}
    {/* <SalesForm {...salesDocumentData} /> */}
  </>)
})

export default PhotographerEvents
