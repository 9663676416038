import React, { useEffect, useState } from 'react'
import { Form, Input, Button, DatePicker, Switch, InputNumber, Radio, Select, Checkbox, Row, Col, Spin, Tooltip, Popover } from 'antd'
import { DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import adminUserListQuery from '~/graphql/queries/adminUserList.gql'
// import _get from 'lodash/get'
import photoPackagesQuery from '~/graphql/queries/photoPackages.gql'
import moment from 'moment'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import CloudUploader from '~/components/CloudUploader'
import CloudImageUploader from '~/components/CloudImageUploader'

// import ContractForm from '~/components/ContractForm'
import province from '~/assets/jsons/changwats' // not available on server
import UserPermission from '~/components/UserPermission'
import FrameImageInput from '~/components/FrameImageInput'
import compose from 'recompose/compose'
import { useQuery } from '@apollo/client'
import QbCustomer from './QbCustomer'
import QbVendor from './QbVendor'
import AdminUserSelect from './AdminUserSelect'
import { inject } from 'mobx-react'
import config from '../config'
import contractDocument from '~/assets/files/contractDocument2_2023.pdf'

// const fileReader = new FileReader()

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { offset: 1, span: 3 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  style: {
    lineHeight: 3
  }
}
const formItemLongLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { offset: 1, span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  style: {
    lineHeight: 3
  }
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 8,
      offset: 10
    }
  },
  style: {
    lineHeight: 3
  }
}
const MINIMUM_SHARE = config?.minimumPercentShare
const MAXIMUM_SHARE = config?.maximumPercentShare

const BrokerDetail = ({ changed, defaultValues, brokerId, form: { getFieldValue, setFieldsValue } }) => {
  const { loading, data } = useQuery(adminUserListQuery, {
    variables: {
      _id: brokerId,
      role: 'broker'
    }
  })
  const brokers = data?.adminUserList?.items
  const broker = brokers?.find((item) => item._id === brokerId)
  const { brokerShare, brokerShareUnit } = broker?.brokerData ?? {}
  useEffect(() => {
    if (!loading && changed) {
      setFieldsValue({ record: { brokerShare, brokerShareUnit } })
    }
  }, [changed, loading, brokerShare, brokerShareUnit, setFieldsValue])
  if (loading) {
    return <Spin style={{ display: 'block' }} />
  }
  return (
    <Form.Item
      labelCol={{ sm: { span: 8 } }}
      wrapperCol={{ sm: { span: 16 } }}
      name={['record', 'brokerShare']}
      labelAlign='left'
      label={<FormattedMessage id='app.broker' defaultMessage='Broker' />}
      initialValue={defaultValues?.record?.brokerShare ?? 0}
      hasFeedback
      rules={[
        { type: 'number',  min: MINIMUM_SHARE, max: MAXIMUM_SHARE },
        {
          validator: (_, v) =>
            v + Number(getFieldValue(['record', 'organizerShare'])) + Number(getFieldValue(['record', 'photographerShare'])) <= MAXIMUM_SHARE
              ? Promise.resolve()
              : Promise.reject(<FormattedMessage id='app.range' defaultMessage='Range {min}-{max}' values={{  min: MINIMUM_SHARE, max: MAXIMUM_SHARE }} />)
        }
      ]}
      style={{ lineHeight: 3 }}
    >
      <InputNumber
        disabled={defaultValues?.record?.startDate && moment().isAfter(defaultValues?.record?.startDate, 'day')}
        min={MINIMUM_SHARE}
        max={MAXIMUM_SHARE}
        precision={2}
      />
    </Form.Item>
  )
}

const OrganizerDetail = ({ changed, defaultValues, organizerId, form: { getFieldValue, setFieldsValue } }) => {
  const { loading, data } = useQuery(adminUserListQuery, {
    variables: {
      _id: organizerId,
      role: 'organizer'
    }
  })
  const organizers = data?.adminUserList?.items
  const organizer = organizers?.find((item) => item._id === organizerId)
  const { organizerShare, organizerShareUnit } = organizer?.organizerData ?? {}
  useEffect(() => {
    if (!loading && changed) {
      setFieldsValue({ record: { organizerShare, organizerShareUnit } })
    }
  }, [changed, loading, organizerShare, organizerShareUnit, setFieldsValue])
  if (loading) {
    return <Spin style={{ display: 'block' }} />
  }

  return (
    <Form.Item
      labelCol={{ sm: { span: 8 } }}
      wrapperCol={{ sm: { span: 16 } }}
      name={['record', 'organizerShare']}
      labelAlign='left'
      label={<FormattedMessage id='app.organizer' defaultMessage='Organizer' />}
      initialValue={defaultValues?.record?.organizerShare ?? 0}
      hasFeedback
      rules={[
        { type: 'number',  min: MINIMUM_SHARE, max: MAXIMUM_SHARE },
        {
          validator: (_, v) =>
            v + Number(getFieldValue(['record', 'photographerShare'])) + Number(getFieldValue(['record', 'brokerShare'])) <= MAXIMUM_SHARE
              ? Promise.resolve()
              : Promise.reject(<FormattedMessage id='app.range' defaultMessage='Range {min}-{max}' values={{  min: MINIMUM_SHARE, max: MAXIMUM_SHARE  }} />)
        }
      ]}
      style={{ lineHeight: 3 }}
    >
      <InputNumber
        disabled={defaultValues?.record?.startDate && moment().isAfter(defaultValues?.record?.startDate, 'day')}
        min={MINIMUM_SHARE}
        max={MAXIMUM_SHARE}
        precision={2}
      />
    </Form.Item>
  )
}

const SalespersonDetail = ({ changed, defaultValues, salespersonId, form: { getFieldValue, setFieldsValue } }) => {
  const { loading, data } = useQuery(adminUserListQuery, {
    variables: {
      _id: salespersonId,
      role: 'salesperson'
    }
  })
  const salespersons = data?.adminUserList?.items
  const salesperson = salespersons?.find((item) => item._id === salespersonId)
  const { salespersonShare, salespersonShareUnit } = salesperson?.salespersonData ?? {}
  console.log({salespersons})
  useEffect(() => {
    if (!loading && changed) {
      setFieldsValue({ record: { salespersonShare, salespersonShareUnit } })
    }
  }, [changed, loading, salespersonShare, salespersonShareUnit, setFieldsValue])
  if (loading) {
    return <Spin style={{ display: 'block' }} />
  }
  return (
    <Form.Item
      labelCol={{ sm: { span: 8 } }}
      wrapperCol={{ sm: { span: 16 } }}
      name={['record', 'salespersonShare']}
      labelAlign='left'
      label={<FormattedMessage id='app.salesperson' defaultMessage='Sales Thai.run' />}
      initialValue={defaultValues?.record?.salespersonShare ?? 0}
      hasFeedback
      rules={[
        { type: 'number',  min: MINIMUM_SHARE, max: MAXIMUM_SHARE },
        {
          validator: (_, v) =>
            v + Number(getFieldValue(['record', 'organizerShare'])) + Number(getFieldValue(['record', 'photographerShare'])) + Number(getFieldValue(['record', 'brokerShare'])) <= MAXIMUM_SHARE
              ? Promise.resolve()
              : Promise.reject(<FormattedMessage id='app.range' defaultMessage='Range {min}-{max}' values={{  min: MINIMUM_SHARE, max: MAXIMUM_SHARE  }} />)
        }
      ]}
      style={{ lineHeight: 3 }}
    >
      <InputNumber
        disabled={defaultValues?.record?.startDate && moment().isAfter(defaultValues?.record?.startDate, 'day')}
        min={MINIMUM_SHARE}
        max={MAXIMUM_SHARE}
        precision={2}
      />
    </Form.Item>
  )
}

const PhotoPackageSelect = compose(inject('user'))(({ user, variables }) => {
  const { packages, photoPackageIds, singlePhotoPrice, unlimitedPackages } = variables
  const [search, setSearch] = useState()
  const { loading, data } = useQuery(photoPackagesQuery, {
    variables: {
      isDefault: [].includes(user.role) ? undefined : true,
      nameSearch: search,
      singlePhotoPrice
    }
  })
  const photoPackages = data?.photoPackages ?? []
  const photoPackagesGroup = {}
  const photoUnlimitedPackages = []
  photoPackages.forEach((p) =>
    p.hasUnlimitedItems
      ? photoUnlimitedPackages.push(p)
      : photoPackagesGroup[p.minItemCount]
        ? photoPackagesGroup[p.minItemCount].push(p)
        : (photoPackagesGroup[p.minItemCount] = [p])
  )
  Object.keys(photoPackagesGroup).map((key) => photoPackagesGroup[key].sort((a, b) => a.name.localeCompare(b.name)))
  photoUnlimitedPackages.sort((a, b) => a.name.localeCompare(b.name))
  const selectedPackagesGroup = {}
  photoPackages.forEach((p) => packages.includes(p._id) && (selectedPackagesGroup[p.minItemCount] = p._id))
  if (loading && ![].includes(user.role)) {
    return <Spin style={{ display: 'block' }} />
  }
  return (
    <>
      <UserPermission grantedTo={[]}>
        <Form.Item
          {...formItemLayout}
          name={['record', 'photoPackageIds']}
          labelAlign='left'
          label={`โปรโมชัน / แพ็กเกจ`}
          initialValue={photoPackageIds}
        >
          <Select
            mode='multiple'
            loading={loading}
            allowClear
            showSearch
            filterOption={false}
            placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}
            onSearch={(v) => setSearch(v)}
            onBlur={() => setSearch()}
            maxTagCount={6}
            style={{ width: '100%' }}
          >
            {photoPackages.map((p) => (
              <Select.Option key={p._id} value={p._id}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </UserPermission>
      <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker']}>
        <Form.Item
          {...formItemLayout}
          name={['record', 'selectPackages']}
          labelAlign='left'
          label={<FormattedMessage id='app.photosInPackage' defaultMessage='Photos in Package' />}
          initialValue={Object.keys(selectedPackagesGroup)}
        >
          {Object.keys(photoPackagesGroup).length ? (
            <Checkbox.Group style={{ marginTop: '0.7em' }}>
              {Object.keys(photoPackagesGroup).map((key) => (
                <Row key={key}>
                  <Checkbox value={key}>
                    <FormattedMessage id='app.numberOfPhotos' defaultMessage='{photos} photos' values={{ photos: key }} />
                  </Checkbox>
                </Row>
              ))}
            </Checkbox.Group>
          ) : (
            <FormattedMessage id='app.noPackage' defaultMessage='No Package' />
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            !!getFieldValue(['record', 'selectPackages']).length &&
            Object.keys(photoPackagesGroup).map(
              (key) =>
                getFieldValue(['record', 'selectPackages']).includes(key) && (
                  <Form.Item
                    {...formItemLayout}
                    wrapperCol={{ sm: { span: 14 } }}
                    key={key}
                    name={['record', 'packages', `${key}`]}
                    labelAlign='left'
                    label={<FormattedMessage id='app.numberOfPhotos' defaultMessage='{photos} photos' values={{ photos: key }} />}
                    initialValue={selectedPackagesGroup[key]}
                  >
                    <Radio.Group>
                      {photoPackagesGroup[key].map((p, i) => (
                        <Row key={p._id}>
                          <Radio value={p._id} style={{ ...(i === 0 && { marginTop: '0.7em' }) }}>
                            {p.name} ({p.packageType} for {p.photoSize} picture{' '}
                            <FormattedNumber value={p.amount} style='currency' currency={config.currency} currencySign='standard' /> , minimum{' '} {/* eslint-disable-line react/style-prop-object */}
                            {p.minItemCount} items)
                          </Radio>
                        </Row>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )
            )
          }
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['record', 'selectUnlimitedPackage']}
          labelAlign='left'
          label={<FormattedMessage id='app.unlimitedPackages' defaultMessage='Unlimited Packages' />}
          initialValue={!!unlimitedPackages}
          valuePropName={'checked'}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue(['record', 'selectUnlimitedPackage']) && (
              <>
                <Form.Item
                  {...formItemLayout}
                  wrapperCol={{ sm: { offset: 4, span: 14 } }}
                  name={['record', 'unlimitedPackages']}
                  initialValue={unlimitedPackages}
                >
                  {Object.keys(photoUnlimitedPackages).length ? (
                    <Radio.Group style={{ marginTop: '0.7em' }}>
                      {photoUnlimitedPackages.map((p) => (
                        <Row key={p._id}>
                          <Radio value={p._id}>
                            {p.name} ({p.packageType} for {p.photoSize} picture{' '}
                            <FormattedNumber value={p.amount} style='currency' currency={config.currency} currencySign='standard' /> , minimum{' '} {/* eslint-disable-line react/style-prop-object */}
                            {p.minItemCount} items)
                          </Radio>
                        </Row>
                      ))}
                    </Radio.Group>
                  ) : (
                    <FormattedMessage id='app.noPackage' defaultMessage='No Package' />
                  )}
                </Form.Item>
              </>
            )
          }
        </Form.Item>
      </UserPermission>
    </>
  )
})

const EventForm = compose(inject('user'))(({ defaultValues, resourceId, onSubmit, user }) => {
  const [form] = Form.useForm()
  const intl = useIntl()
  const { getFieldValue, resetFields, setFieldsValue } = form
  const [loading, setLoading] = useState(false)
  const [organizerChanged, setOrganizerChanged] = useState(false)
  const [brokerChanged, setBrokerChanged] = useState(false)
  const [salespersonChanged, setSalespersonChanged] = useState(false)
  const handleOrganizerId = () => {
    setOrganizerChanged(true)
  }
  const handleBrokerId = () => {
    setBrokerChanged(true)
  }
  const handleSalespersonId = () => {
    setSalespersonChanged(true)
  }
  const handleSinglePhotoPrice = () => {
    const selectPackages = getFieldValue(['record', 'packages']) ?? []
    setFieldsValue({
      record: {
        packages: Object.keys(selectPackages).reduce((prev, curr) => ({ ...prev, [curr]: {} }), {}),
        photoPackageEnabled: false,
        photoPackageIds: [],
        selectPackages: [],
        selectUnlimitedPackage: false,
        unlimitedPackages: null
      }
    })
  }
  const handleFormSubmit = async (values) => {
    setLoading(true)
    const {
      date,
      // shareInterval,
      media,
      frame,
      record: {
        officialPartner,
        registrationStartsAt,
        registrationEndsAt,
        registrationMethod,
        brokerId,
        brokerShare,
        brokerShareUnit,
        organizerId,
        organizerShare,
        organizerShareUnit,
        photographerShare,
        photographerNumber,
        photoPackageEnabled,
        packages,
        selectPackages,
        selectUnlimitedPackage,
        unlimitedPackages,
        photoPackageIds,
        ...record
      }
    } = values
    try {
      await onSubmit({
        media: {
          ...media,
          ...frame
        },
        record: {
          ...record,
          // shareEndsAt: moment(date)
          //   .endOf('day')
          //   .add(shareInterval - 1, 'days'),
          startDate: date,
          endDate: date,
          registrationStartsAt,
          registrationEndsAt,
          registrationMethod,
          photographerNumber: ['auto', 'manual', 'free'].includes(registrationMethod) ? photographerNumber : null,
          officialPartner,
          photoPackageEnabled,
          photoPackageIds: photoPackageEnabled
            ? [
              ...(photoPackageIds?.length ? photoPackageIds : []),
              ...(packages ? Object.keys(packages).reduce((prev, curr) => (packages[curr] ? [...prev, packages[curr]] : prev), []) : []),
              ...(selectUnlimitedPackage && unlimitedPackages?.length ? [unlimitedPackages] : [])
            ]
            : [],
          ...(officialPartner && {
            brokerId,
            brokerShare,
            organizerId,
            organizerShare,
            photographerShare
          })
        }
      })
      resetFields()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  return (
    <Form
      form={form}
      className='nonPrintDiv'
      onFinish={handleFormSubmit}
      scrollToFirstError={{ behavior: 'smooth' }}
      style={{ margin: '0 4em', padding: '2em 0 4em 0' }}
    >
      <h2>CREATE EVENT</h2>
      <div style={{ background: 'white', border: '1px solid LightGrey', borderRadius: '25px', padding: '1em' }}>
        <Form.Item
          style={{ color: 'black', fontSize: '1.2em', background: 'WhiteSmoke', borderRadius: '5px', padding: '7.5px 10px', margin: '15px' }}
        >
          <FormattedMessage id='app.generalInformation' defaultMessage='General Information' />
        </Form.Item>
        <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker', 'salesperson']}>
          <Form.Item
            {...formItemLayout}
            name={['record', 'slug']}
            wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
            labelAlign='left'
            label='URL'
            initialValue={defaultValues?.record?.slug}
            rules={[{ required: true },
              {
                pattern: /^[a-z0-9]+([/.-][a-z0-9]+)*$/i,
                message: intl.formatMessage({
                  id: 'app.urlValidationMessage1',
                  defaultMessage: 'Please input only English charactor and digit and - . /'
                })
              }
            ]}
          >
            <Input addonBefore={config.webBaseUrl} />
          </Form.Item>
        </UserPermission>
        <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker', 'photographer', 'salesperson']}>
          <Form.Item
            {...formItemLayout}
            name={['record', 'title']}
            wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
            labelAlign='left'
            label={<FormattedMessage id='app.eventTitle' defaultMessage='Event Title' />}
            initialValue={defaultValues?.record?.title}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {config.platform === 'thai' ? (
            <Form.Item
              {...formItemLayout}
              name={['record', 'province']}
              labelAlign='left'
              label={<FormattedMessage id='app.eventProvince' defaultMessage='Event Province' />}
              initialValue={defaultValues?.record?.province}
              // rules={[{ required: true }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder={<FormattedMessage id='app.chooseYourProvince' defaultMessage='Choose your province!' />}
                showSearch
              >
                {Object.values(province).map((item) => (
                  <Select.Option key={item.name.en} value={item.name.th}>
                    {item.name.th}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              {...formItemLayout}
              name={['record', 'location']}
              labelAlign='left'
              label={<FormattedMessage id='app.eventVenue' defaultMessage='Event Venue' />}
              initialValue={defaultValues?.record?.location}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          )}
        </UserPermission>
        <Form.Item
          {...formItemLayout}
          name={['date']}
          labelAlign='left'
          label={<FormattedMessage id='app.eventDate' defaultMessage='Event Date' />}
          initialValue={defaultValues?.record?.startDate && moment(defaultValues.record.startDate)}
          rules={[{ required: true }]}
        >
          <DatePicker
            disabled={defaultValues?.record?.startDate && moment().isAfter(defaultValues?.record?.startDate, 'day')}
            disabledDate={(current) => moment().add(-30, 'days') > current}
            style={{ minWidth: '200px' }}
          />
        </Form.Item>
        <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker', 'salesperson']}>
          <Form.Item
            {...formItemLayout}
            name={['record', 'uploadableAt']}
            labelAlign='left'
            label={<FormattedMessage id='app.startUploadOn' defaultMessage='Start Upload On' />}
            initialValue={defaultValues?.record?.uploadableAt && moment(defaultValues.record.uploadableAt)}
          >
            <DatePicker showTime={{ minuteStep: 15, secondStep: 60 }} format='DD/MM/YYYY HH:mm:ss' style={{ minWidth: '200px' }} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['record', 'searchableAt']}
            labelAlign='left'
            label={<FormattedMessage id='app.openSearchOn' defaultMessage='Start Upload On' />}
            initialValue={defaultValues?.record?.searchableAt && moment(defaultValues?.record?.searchableAt)}
          >
            <DatePicker showTime={{ minuteStep: 30, secondStep: 60 }} format='DD/MM/YYYY HH:mm:ss' style={{ minWidth: '200px' }} />
          </Form.Item>
        </UserPermission>
        <Form.Item
          {...formItemLayout}
          name={['record', 'raceType']}
          labelAlign='left'
          label={<FormattedMessage id='app.raceType' defaultMessage='Race Type' />}
          initialValue={defaultValues?.record?.raceType}
        >
          <Radio.Group>
            <Radio value='road'>
              <FormattedMessage id='app.road' defaultMessage='Road' />
            </Radio>
            <Radio value='trail'>
              <FormattedMessage id='app.trail' defaultMessage='Trail' />
            </Radio>
            <Radio value='triathlon'>
              <FormattedMessage id='app.triathlon' defaultMessage='Triathlon' />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['record', 'raceDistance']}
          wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
          labelAlign='left'
          label={<FormattedMessage id='app.raceDistance' defaultMessage='Race Distance' />}
          initialValue={defaultValues?.record?.raceDistance}
        >
          <Radio.Group buttonStyle='solid'>
            <Radio value={100}>
              <FormattedMessage id='app.100k' defaultMessage='100k up' />
            </Radio>
            <Radio value={70}>
              <FormattedMessage id='app.70k' defaultMessage='70-99.9k' />
            </Radio>
            <Radio value={42}>
              <FormattedMessage id='app.42k' defaultMessage='42-69.9k' />
            </Radio>
            <Radio value={21}>
              <FormattedMessage id='app.21k' defaultMessage='21-41.9k' />
            </Radio>
            <Radio value={10}>
              <FormattedMessage id='app.10k' defaultMessage='10-20.9k' />
            </Radio>
            <Radio value={5}>
              <FormattedMessage id='app.5k' defaultMessage='5-9.9k' />
            </Radio>
            <Radio value={0}>
              <FormattedMessage id='app.0k' defaultMessage='0-4.9k' />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['record', 'creditTargets']}
          labelAlign='left'
          label={<FormattedMessage id='app.creditTargets' defaultMessage='Credit Targets' />}
          initialValue={defaultValues?.record?.creditTargets}
          rules={[{ required: true }]}
        >
          <Select placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}>
            <Select.Option key='event' value='event'>
              <FormattedMessage id='app.event' defaultMessage='Event' />
            </Select.Option>
            <Select.Option key='photographer' value='photographer'>
              <FormattedMessage id='app.photographer' defaultMessage='Photographer' />
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name={['record', 'photoDownloadMode']}
          labelAlign='left'
          label={<FormattedMessage id='app.downloadMode' defaultMessage='Download Mode' />}
          initialValue={defaultValues?.record?.photoDownloadMode ?? 'priced'}
          rules={[{ required: true, message: 'Please Select Mode!' }]}
        >
          <Select disabled={['broker'].includes(user.role)} placeholder='Please select a mode' style={{ width: 240 }}>
            <Select.Option key='free' value='free'>
              <FormattedMessage id='app.free' defaultMessage='Free' />
            </Select.Option>
            <Select.Option key='priced' value='priced'>
              <FormattedMessage id='app.priced' defaultMessage='Priced' />
            </Select.Option>
            {/* <Select.Option key='restricted' value='restricted'>
              <FormattedMessage id='app.restricted' defaultMessage='Restricted' />
            </Select.Option> */}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() =>
            getFieldValue(['record', 'photoDownloadMode']) === 'priced' && (
              <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker', 'salesperson']}>
                <Form.Item
                  {...formItemLayout}
                  name={['record', 'singlePhotoPrice']}
                  labelAlign='left'
                  label={<FormattedMessage id='app.singlePhotoPrice' defaultMessage='Single Photo Price' />}
                  initialValue={defaultValues?.record?.singlePhotoPrice}
                  rules={[{ required: true }]}
                >
                  <Select disabled={!!resourceId} onChange={handleSinglePhotoPrice} placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}>
                    {config.singlePhotoPrices.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name={['record', 'enableVideoSales']}
                  labelAlign='left'
                  label={<FormattedMessage id='app.enableVideoSales' defaultMessage='Enable Video Sales' />}
                  initialValue={defaultValues?.record?.enableVideoSales}
                  valuePropName={'checked'}
                >
                  <Switch />
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {() =>
                    getFieldValue(['record', 'enableVideoSales']) && (
                      <Form.Item
                        {...formItemLayout}
                        name={['record', 'singleVideoPrice']}
                        labelAlign='left'
                        label={<FormattedMessage id='app.singleVideoPrice' defaultMessage='Single Video Price' />}
                        initialValue={defaultValues?.record?.singleVideoPrice}
                        rules={[{ required: true }]}
                      >
                        <Select disabled={!!resourceId} placeholder={<FormattedMessage id='app.select' defaultMessage='Please Select...' />}>
                          {config.singleVideoPrices.map((item) => (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>)
                  }
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name={['record', 'officialPartner']}
                  labelAlign='left'
                  label={<FormattedMessage id='app.enablePartner' defaultMessage='Enable Partner' />}
                  initialValue={defaultValues?.record?.officialPartner || (['broker'].includes(user.role) && true)}
                  valuePropName={'checked'}
                >
                  <Switch disabled={['broker', 'salesperson'].includes(user.role)} />
                </Form.Item>
                <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin']}>
                  <Form.Item
                    {...formItemLayout}
                    name={['record', 'timestamp']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.timestamp' defaultMessage='Timestamp' />}
                    initialValue={defaultValues?.record?.timestamp}
                    valuePropName={'checked'}
                  >
                    <Switch />
                  </Form.Item>
                </UserPermission>
              </UserPermission>
            )
          }
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() =>
            getFieldValue(['record', 'officialPartner']) && (
              <>
                <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'salesperson']}>
                  <Form.Item
                    {...formItemLayout}
                    name={['record', 'organizerId']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.organizer' defaultMessage='Organizer' />}
                    initialValue={defaultValues?.record?.organizerId}
                  >
                    <AdminUserSelect onChange={handleOrganizerId} role={'organizer'} />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name={['record', 'brokerId']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.broker' defaultMessage='Broker' />}
                    initialValue={defaultValues?.record?.brokerId}
                  >
                    {user.role === 'broker' ? (
                      <Input defaultValue={defaultValues?.record?.createdByUser?.username} />
                    ) : (
                      <AdminUserSelect onChange={handleBrokerId} role={'broker'} />
                    )}
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name={['record', 'salespersonId']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.salesperson' defaultMessage='Salesperson' />}
                    initialValue={defaultValues?.record?.salespersonId}
                  >
                    <AdminUserSelect onChange={handleSalespersonId} role={'salesperson'} />
                  </Form.Item>
                </UserPermission>
                <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker', 'salesperson']}>
                  <Form.Item
                    {...formItemLayout}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                    labelAlign='left'
                    label={<FormattedMessage id='app.sharePercentage' defaultMessage='% Share' />}
                  >
                    <Row type='flex'>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                        <OrganizerDetail
                          changed={organizerChanged}
                          defaultValues={defaultValues}
                          organizerId={getFieldValue(['record', 'organizerId'])}
                          form={{ getFieldValue, setFieldsValue }}
                        />
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                        <BrokerDetail
                          changed={brokerChanged}
                          defaultValues={defaultValues}
                          brokerId={getFieldValue(['record', 'brokerId'])}
                          form={{ getFieldValue, setFieldsValue }}
                        />
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                        <Form.Item
                          labelCol={{ sm: { span: 8 } }}
                          wrapperCol={{ sm: { span: 16 } }}
                          name={['record', 'photographerShare']}
                          labelAlign='left'
                          label={<FormattedMessage id='app.photographer' defaultMessage='Photographer' />}
                          initialValue={defaultValues?.record?.photographerShare ?? 0}
                          hasFeedback
                          rules={[
                            { type: 'number', min: MINIMUM_SHARE, max: MAXIMUM_SHARE },
                            {
                              validator: (_, v) =>
                                v + Number(getFieldValue(['record', 'organizerShare'])) + Number(getFieldValue(['record', 'brokerShare'])) <= MAXIMUM_SHARE
                                  ? Promise.resolve()
                                  : Promise.reject(
                                    <FormattedMessage id='app.range' defaultMessage='Range {min}-{max}' values={{ min: MINIMUM_SHARE, max: MAXIMUM_SHARE  }} />
                                  )
                            }
                          ]}
                          style={{ lineHeight: 3 }}
                        >
                          <InputNumber
                            disabled={defaultValues?.record?.startDate && moment().isAfter(defaultValues?.record?.startDate, 'day')}
                            min={MINIMUM_SHARE}
                            max={MAXIMUM_SHARE}
                            precision={2}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                        <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'salesperson']}>
                          <SalespersonDetail
                            changed={salespersonChanged}
                            defaultValues={defaultValues}
                            salespersonId={getFieldValue(['record', 'salespersonId'])}
                            form={{ getFieldValue, setFieldsValue }}
                          />
                        </UserPermission>
                      </Col>
                    </Row>
                  </Form.Item>
                </UserPermission>
                {/* <Form.Item
                    {...formItemLongLayout}
                    labelCol={{ sm: { offset: 1, span: 8 } }}
                    name={['record', 'consentDocumentUrl']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.consentDocument' defaultMessage='Consent Document for selling photos on ThaiRun Photo' />}
                    initialValue={defaultValues?.record?.consentDocumentUrl}
                    // rules={[{ required: true, message: <FormattedMessage id="app.consentDocumentRequired" defaultMessage="Please upload document" /> }]}
                  >
                    <CloudUploader provider='gs' path='documents' />
                  </Form.Item> */}
              </>
            )
          }
        </Form.Item>
        {/* <Form.Item noStyle shouldUpdate>
          {() =>
            getFieldValue(['record', 'singlePhotoPrice']) && (<>
              <Form.Item
                {...formItemLayout}
                name={['record', 'brokerId']}
                labelAlign='left'
                label={<FormattedMessage id='app.broker' defaultMessage='Broker' />}
                initialValue={defaultValues?.record?.brokerId}
              >
                {user.role === 'broker' ? <Input defaultValue={defaultValues?.record?.createdByUser?.username}/>
                  : <AdminUserSelect onChange={handleBrokerId} role={'broker'} />}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                labelAlign='left'
                label={<FormattedMessage id='app.sharePercentage' defaultMessage='% Share' />}
              >
                <Row type='flex'>
                  <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                    <OrganizerDetail
                      changed={organizerChanged}
                      defaultValues={defaultValues}
                      organizerId={getFieldValue(['record', 'organizerId'])}
                      form={{ getFieldValue, setFieldsValue }}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 7 }}>
                    <BrokerDetail
                      changed={brokerChanged}
                      defaultValues={defaultValues}
                      brokerId={getFieldValue(['record', 'brokerId'])}
                      form={{ getFieldValue, setFieldsValue }}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 10 }}>
                    <Form.Item
                      labelCol={{ sm: { span: 8 } }}
                      wrapperCol={{ sm: { span: 16 } }}
                      name={['record', 'photographerShare']}
                      labelAlign='left'
                      label={<FormattedMessage id='app.photographer' defaultMessage='Photographer' />}
                      initialValue={defaultValues?.record?.photographerShare ?? 0}
                      hasFeedback
                      rules={[
                        { type: 'number', min: 0, max: 75 },
                        {
                          validator: (_, v) =>
                            v + Number(getFieldValue(['record', 'brokerShare'])) + Number(getFieldValue(['record', 'organizerShare'])) <= 75
                              ? Promise.resolve()
                              : Promise.reject(
                                new Error(
                                  <FormattedMessage id='app.range' defaultMessage='Range {min}-{max}' values={{ min: 0, max: 75 }} />
                                )
                              )
                        }
                      ]}
                      style={{ lineHeight: 3 }}
                    >
                      <InputNumber
                        disabled={defaultValues?.record?.startDate && moment().isAfter(defaultValues?.record?.startDate, 'day')}
                        min={0}
                        max={75}
                        precision={2}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </>)
          }
        </Form.Item> */}
        {/* {config.platform !== 'indo' && 
          <Form.Item
            {...formItemLongLayout}
            name={['record', 'equalPhotographerShare']}
            labelAlign='left'
            label={<FormattedMessage id='app.equalPhotographerShare' defaultMessage='Equal Photographer Share' />}
            initialValue={!!defaultValues?.record?.equalPhotographerShare}
            valuePropName={'checked'}
          >
            <Switch />
          </Form.Item>
        } */}

        <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker', 'salesperson']}>
          <Form.Item noStyle shouldUpdate>
            {() =>
              getFieldValue(['record', 'singlePhotoPrice']) && (
                <Form.Item
                  {...formItemLayout}
                  name={['record', 'photoPackageEnabled']}
                  labelAlign='left'
                  label={<FormattedMessage id='app.enablePackages' defaultMessage='Enable Packages' />}
                  initialValue={defaultValues?.record?.photoPackageEnabled}
                  valuePropName={'checked'}
                >
                  <Switch />
                </Form.Item>
              )
            }
          </Form.Item>
        </UserPermission>
        <Form.Item noStyle shouldUpdate>
          {() =>
            getFieldValue(['record', 'photoPackageEnabled']) && (
              <PhotoPackageSelect
                variables={{
                  packages: defaultValues?.record?.photoPackages?.filter((p) => !p?.hasUnlimitedItems)?.map((p) => p._id) ?? [],
                  photoPackageIds: defaultValues?.record?.photoPackageIds,
                  singlePhotoPrice: getFieldValue(['record', 'singlePhotoPrice']),
                  unlimitedPackages: defaultValues?.record?.photoPackages?.find((p) => !!p?.hasUnlimitedItems)?._id
                }}
              />
            )
          }
        </Form.Item>
        <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker', 'photographer', 'salesperson']}>
          <Form.Item
            {...formItemLayout}
            name={['record', 'participantCount']}
            labelAlign='left'
            label={<FormattedMessage id='app.participantCount' defaultMessage='participantCount' />}
            initialValue={defaultValues?.record?.participantCount}
            // rules={[{ required: true }]}
          >
            <InputNumber min={0} />
          </Form.Item>
          {/* <UserPermission grantedTo={['broker', 'salesperson']}>
            <Form.Item
              {...formItemLayout}
              name={['shareInterval']}
              labelAlign='left'
              label={<FormattedMessage id='app.shareDuration' defaultMessage='Share Duration' />}
              initialValue={
                defaultValues?.record?.shareInterval ??
                moment(defaultValues?.record?.shareEndsAt).diff(defaultValues?.record?.startDate, 'days') + 1 ??
                (getFieldValue(['record', 'officialPartner']) ? 30 : undefined)
              }
              rules={[{ type: 'number', min: 1, message: 'Minimum value is 1' }]}
            >
              <InputNumber
                disabled
                addonAfter={<FormattedMessage id='app.daySinceEventDate' defaultMessage='Day (Since Event Date)' />}
                style={{ verticalAlign: 'middle' }}
              />
            </Form.Item>
          </UserPermission> */}
          <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin']}>
            <Form.Item
              {...formItemLayout}
              name={['record', 'hidden']}
              labelAlign='left'
              label={<FormattedMessage id='app.hidden' defaultMessage='Hidden' />}
              initialValue={defaultValues?.record?.hidden}
              valuePropName={'checked'}
            >
              <Switch />
            </Form.Item>
          </UserPermission>
          <Form.Item
            {...formItemLayout}
            name={['record', 'uploadNotice']}
            labelAlign='left'
            label={<FormattedMessage id='app.uploadNotice' defaultMessage='Upload Notice' />}
            initialValue={defaultValues?.record?.uploadNotice}
          >
            <Input.TextArea />
          </Form.Item>
          <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin']}>
            <Form.Item
              {...formItemLayout}
              name={['record', 'photoTagFaces']}
              labelAlign='left'
              label={<FormattedMessage id='app.faceSearch' defaultMessage='Face Search' />}
              initialValue={defaultValues?.record?.photoTagFaces}
              valuePropName={'checked'}
            >
              <Switch />
            </Form.Item>
            {config.platform !== 'indo' && 
              <Form.Item
                {...formItemLayout}
                name={['record', 'photoTagText']}
                labelAlign='left'
                label={<FormattedMessage id='app.bibSearch' defaultMessage='BIB Search' />}
                initialValue={defaultValues?.record?.photoTagText}
                valuePropName={'checked'}
              >
                <Switch />
              </Form.Item>
            }
          </UserPermission>
        </UserPermission>
        <UserPermission grantedTo={['superadmin', 'admin']}>
          <Form.Item
            {...formItemLayout}
            name={['record', 'approved']}
            labelAlign='left'
            label={<FormattedMessage id='app.approved' defaultMessage='Approved' />}
            initialValue={defaultValues?.record?.approved}
            valuePropName={'checked'}
          >
            <Switch />
          </Form.Item>
        </UserPermission>
        <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker', 'photographer', 'salesperson']}>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.username' defaultMessage='Username' />}>
            <span>{defaultValues?.record?.createdByUser?.username}</span>
          </Form.Item>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.email' defaultMessage='Email' />}>
            <span>{defaultValues?.record?.createdByUser?.profile.email}</span>
          </Form.Item>
        </UserPermission>
        <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin']}>
          <Form.Item {...formItemLayout} labelAlign='left' label={<FormattedMessage id='app.phone' defaultMessage='Phone' />}>
            <span>{defaultValues?.record?.createdByUser?.profile.phone}</span>
          </Form.Item>
        </UserPermission>
        <UserPermission grantedTo={['superadmin', 'admin']}>
          {resourceId && (
            <>
              <Form.Item {...formItemLayout} labelAlign='left' label='Quickbooks Customer'>
                <QbCustomer eventId={resourceId} />
              </Form.Item>
              <Form.Item {...formItemLayout} labelAlign='left' label='Quickbooks Vendor'>
                <QbVendor eventId={resourceId} />
              </Form.Item>
            </>
          )}
        </UserPermission>
        <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker', 'salesperson']}>
          <Form.Item style={{ color: 'black', fontSize: '1.2em', background: 'WhiteSmoke', borderRadius: '5px', padding: '10px', margin: '15px' }}>
            <Col>
              <FormattedMessage id='app.photographerInformation' defaultMessage='Photographer Information' />
            </Col>
          </Form.Item>
          <Form.Item>
            <Form.Item
              labelCol={{ xs: { span: 24 }, sm: { offset: 1, span: 3 } }}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 20 } }}
              name={['record', 'registrationMethod']}
              labelAlign='left'
              label={<FormattedMessage id='app.registrationMethod' defaultMessage='Photographer Selection' />}
              initialValue={defaultValues?.record?.registrationMethod}
            >
              <Radio.Group>
                <Radio value='manual'>
                  <FormattedMessage id='app.registrationMethod.manual' defaultMessage='Custom' />
                </Radio>
                <Radio value='auto'>
                  <FormattedMessage id='app.registrationMethod.auto' defaultMessage='Lotto' />
                </Radio>
                <Radio value='free'>
                  <FormattedMessage id='app.registrationMethod.free' defaultMessage='Queue' />
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {() =>
                ['auto', 'free'].includes(getFieldValue(['record', 'registrationMethod'])) && (
                  <>
                    {['auto', 'free'].includes(getFieldValue(['record', 'registrationMethod'])) && (
                      <Form.Item
                        {...formItemLayout}
                        name={['record', 'photographerNumber']}
                        labelAlign='left'
                        label={<FormattedMessage id='app.photographerLimit' defaultMessage='Photographer Limit' />}
                        initialValue={defaultValues?.record?.photographerNumber}
                        rules={[{ required: ['auto', 'free'].includes(getFieldValue(['record', 'registrationMethod'])) }]}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                    )}
                    <Form.Item
                      {...formItemLayout}
                      name={['record', 'registrationStartsAt']}
                      labelAlign='left'
                      label={<FormattedMessage id='app.registrationStartsAt' defaultMessage='Registration Starts' />}
                      initialValue={defaultValues?.record?.registrationStartsAt && moment(defaultValues?.record?.registrationStartsAt)}
                      rules={[{ required: ['auto', 'free'].includes(getFieldValue(['record', 'registrationMethod'])) }]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      name={['record', 'registrationEndsAt']}
                      labelAlign='left'
                      label={<FormattedMessage id='app.registrationEndsAt' defaultMessage='Registration Ends' />}
                      initialValue={defaultValues?.record?.registrationEndsAt && moment(defaultValues?.record?.registrationEndsAt)}
                      rules={[{ required: ['auto', 'free'].includes(getFieldValue(['record', 'registrationMethod'])) }]}
                    >
                      <DatePicker />
                    </Form.Item>
                  </>
                )
              }
            </Form.Item>
            {/* <Form.Item {...formItemLayout} labelAlign='left' label={`ส่งคำเชิญให้ช่างภาพ`}>
              <AdminUserSelect role={'photographer'} />
            </Form.Item>
            <Form.Item {...formItemLayout} labelAlign='left' label={`ช่างภาพ BLACKLIST`}>
              <AdminUserSelect role={'photographer'} />
            </Form.Item> */}
          </Form.Item>
        </UserPermission>
        <Form.Item style={{ color: 'black', fontSize: '1.2em', background: 'WhiteSmoke', borderRadius: '5px', padding: '10px', margin: '15px' }}>
          <Col>
            <FormattedMessage id='app.approvalDocument' defaultMessage='Approval Document' />
          </Col>
        </Form.Item>
        <Form.Item>
          <Form.Item
            {...formItemLayout}
            name={['record', 'coverUrl']}
            labelAlign='left'
            label={<><FormattedMessage id='app.coverImage' defaultMessage='Cover Image' />&nbsp;&nbsp;<Popover content='1200 x 422 px'>
            <ExclamationCircleOutlined /> 
          </Popover></>}
            initialValue={defaultValues?.record?.coverUrl}
            rules={[{ required: true }]}
          >
            <CloudImageUploader path='events/cover' provider='cf' accept='image/jpeg' resize={{ maxWidth: 1200, mode: 'contain' }} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() =>
              getFieldValue(['record', 'enableVideoSales']) && (
                <Form.Item
                  {...formItemLayout}
                  name={['record', 'videoCoverUrl']}
                  labelAlign='left'
                  label={
                    <>
                      <FormattedMessage id='app.videoCover' defaultMessage='Video Cover' />&nbsp;&nbsp;<Popover content='1200 x 422 px'>
                      <ExclamationCircleOutlined /> 
                      </Popover>
                    </>
                  }
                  initialValue={defaultValues?.record?.videoCoverUrl}
                  rules={[{ required: true }]}
                >
                  <CloudImageUploader path='events/cover' provider='cf' accept='image/jpeg' resize={{ maxWidth: 1200, mode: 'contain' }} />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() =>
              (getFieldValue(['record', 'photoDownloadMode']) === 'free' || getFieldValue(['record', 'officialPartner']))&& (
                <Form.Item
                  {...formItemLayout}
                  name={['frame']}
                  labelAlign='left'
                  label={<FormattedMessage id='app.eventFrame' defaultMessage='Photo Frame' />}
                >
                  <FrameImageInput
                    portraitPreviewUrl={defaultValues?.record?.photoFrame?.portraitPreviewUrl}
                    landscapePreviewUrl={defaultValues?.record?.photoFrame?.landscapePreviewUrl}
                  />
                </Form.Item>
              )
            }
          </Form.Item>
          {config.platform === 'thai' && ['superadmin', 'admin', 'operation_admin', 'broker', 'salesperson'].includes(user.role) && 
            <Form.Item noStyle shouldUpdate>
              {() =>
                getFieldValue(['record', 'officialPartner']) && (
                  <Form.Item
                    {...formItemLayout}
                    name={['Download']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.downloadContractDocument' defaultMessage='Download Contract Document' />}
                  >
                    <><Button icon={<DownloadOutlined />} href={contractDocument} download="contractDocument_2023.pdf"><FormattedMessage id='app.contractDocument' defaultMessage='Contract Document'/></Button>
                    <Tooltip placement="right" title={<FormattedMessage id='app.pleaseDownloadContractDocument'  defaultMessage='Please download contract document and complete the information. Then upload in contract document field.'/>}>
                    &nbsp;&nbsp;<ExclamationCircleOutlined />
                    </Tooltip></>
                  </Form.Item>
                )
              }
            </Form.Item>} 
            <Form.Item noStyle shouldUpdate>
              {() =>
                getFieldValue(['record', 'officialPartner']) ? (
                  getFieldValue(['record', 'photoDownloadMode']) === 'priced' && (
                    <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'broker', 'salesperson']}>
                      <Form.Item
                        {...formItemLongLayout}
                        name={['record', 'contractDocumentUrl']}
                        labelAlign='left'
                        label={<FormattedMessage id='app.contractDocument' defaultMessage='Contract Document for partner' />}
                        initialValue={defaultValues?.record?.contractDocumentUrl}
                      >
                        <CloudUploader provider='gs' path='documents' accept='.pdf,image/*' />
                      </Form.Item>
                      <Form.Item
                        {...formItemLayout}
                        name={['record', 'remarks']}
                        wrapperCol={{ xs: { span: 12 }, sm: { span: 12 } }}
                        labelAlign='left'
                        label={<FormattedMessage id='app.remarks.document' defaultMessage='Remarks Document' />}
                        initialValue={defaultValues?.record?.remarks}
                        // rules={[{ required: true }]}
                      >
                        <Input placeholder='โปรดระบุเอกสารที่ยังไม่ครบ' />
                      </Form.Item>
                    </UserPermission>)
                  ) : (
                  <UserPermission grantedTo={['superadmin', 'admin', 'operation_admin', 'photographer']}>
                    <Form.Item
                    {...formItemLongLayout}
                    labelCol={{ sm: { offset: 1, span: 8 } }}
                    name={['record', 'consentDocumentUrl']}
                    labelAlign='left'
                    label={<FormattedMessage id='app.consentDocument' defaultMessage='Consent Document for selling photos on ThaiRun Photo' />}
                    initialValue={defaultValues?.record?.consentDocumentUrl}
                    // rules={[{ required: true, message: <FormattedMessage id="app.consentDocumentRequired" defaultMessage="Please upload document" /> }]}
                    >
                      <CloudUploader provider='gs' path='documents' />
                    </Form.Item>
                  </UserPermission>
                )
              }
            </Form.Item>
          </Form.Item>
        <Form.Item style={{ marginTop: 20 }}>
          <Form.Item {...tailFormItemLayout}>
            <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
              <FormattedMessage id='app.submit' defaultMessage='Submit' />
            </Button>
          </Form.Item>
        </Form.Item>
      </div>
    </Form>
  )
})

export default EventForm
