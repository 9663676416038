import React, { useEffect } from 'react'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { Table, Input, Button, Row, DatePicker, Tag, Spin, Popover, Col } from 'antd'
import { CheckCircleFilled, ClockCircleFilled, InfoCircleOutlined, TagOutlined } from '@ant-design/icons'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import eventListQuery from '../graphql/queries/eventList.gql'
import eventSalesQuery from '../graphql/queries/eventSales.gql'
// import findEventSummaryQuery from '../graphql/queries/findEventSummary.gql'
import RaceTypeSelect from '~/components/RaceTypeSelect'
import { compose } from 'recompose'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import config from '../config'
import ExportData from '../components/ExportData'

const CouponUsagePopOverContent = ({ _id }) => {
  const { error, loading, data } = useQuery(eventSalesQuery, {
    variables: {
      _id
    }
  })
  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block' }} />
  }
  return (
    <div>
      {data.event.couponUsages
        .slice()
        .sort((a, b) => a?.couponCodeType?.name?.localeCompare(b?.couponCodeType?.name))
        .map(
          ({ couponCodeType, couponCodeTypeId, orderCount }, index) =>
            couponCodeType?.name && (
              <Row key={index} type='flex'>
                <Col span={20}>{couponCodeType.name}</Col>
                <Col span={4}>{<Link to={`/coupons?couponTypeId=${couponCodeTypeId}`}>{orderCount}</Link>}</Col>
              </Row>
            )
        )}
    </div>
  )
}

const PhotoSalePopOverContent = ({ _id }) => {
  const { error, loading, data } = useQuery(eventSalesQuery, {
    variables: {
      _id
    }
  })
  if (error) {
    return 'Error'
  }
  if (loading) {
    return <Spin style={{ display: 'block' }} />
  }
  return (
    <div>
      {data.event.photoSales
        .slice()
        .sort((a, b) => a?.photoPackage?.name?.localeCompare(b?.photoPackage?.name))
        .map(({ orderCount, photoPackage }, index) => (
          <Row key={index} type='flex'>
            <Col span={20}>{photoPackage?.name ?? <FormattedMessage id='app.noPackage' defaultMessage='No Package' />}</Col>
            <Col span={4}>{orderCount}</Col>
          </Row>
        ))}
    </div>
  )
}

const EventSummary = ({ intl, router, user }) => {
  const { location } = router
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const { s: titleSearch, p: page, from: startDateFrom, to: startDateTo, type: eventType, rtype: raceType } = location.query
  // console.log({ startDateFrom, startDateTo })
  const {
    error: eventListError,
    loading: eventListLoading,
    data: eventListData
  } = useQuery(eventListQuery, {
    variables: {
      titleSearch,
      eventType,
      raceType,
      isAccessibleOnly: true,
      page: parseInt(page, 10) || 1,
      ...(startDateFrom && startDateTo
        ? { startDate: { gte: moment(startDateFrom).toISOString(), lte: moment(startDateTo).endOf('day').toISOString() } }
        : {})
    },
    fetchPolicy: 'network-only'
  })
  // const {
  //   error: findEventSummaryError,
  //   loading: findEventSummaryLoading,
  //   data: findEventSummaryData,
  // } = useQuery(findEventSummaryQuery, {
  //   variables: {
  //     titleSearch: titleSearch || '',
  //     startDate: startDateFrom && moment(startDateFrom).toISOString(),
  //     endDate: startDateTo && moment(startDateTo).endOf('day').toISOString(),
  //     eventType,
  //     raceType,
  //     limit: 3000,
  //   },
  // })
  useEffect(() => {
    // if (sessionStorage.getItem('noticeModal') === 'unseen') {
    //   setVisible(true)
    // }
    if (!location.query.from && !location.query.to) {
      location.query = {
        ...location.query,
        from: moment().subtract(3, 'months').format(moment.HTML5_FMT.DATE),
        to: moment().format(moment.HTML5_FMT.DATE)
      }
      // setFieldsValue({ date: [moment().subtract(1, 'week'), moment()], email, event, method })
      // setEventsSearch()
    }
  }, [location.query.from, location.query.to, location.query])

  const handleRaceTypeChange = (raceType) => {
    const { rtype: _raceType, ...query } = location.query
    location.query = {
      ...query,
      ...(raceType !== 'all' && { rtype: raceType })
    }
  }

  const handleCompletedDateRange = ([$startDateFrom, $startDateTo]) => {
    location.query = {
      ...location.query,
      ...(($startDateFrom && $startDateTo) && {
        from: $startDateFrom.format(moment.HTML5_FMT.DATE),
        to: $startDateTo.format(moment.HTML5_FMT.DATE)
      })
    }
  }

  const handleTitleSearchChange = (titleSearch) => {
    const { s: _titleSearch, ...query } = location.query
    location.query = {
      ...query,
      ...(titleSearch && { s: titleSearch })
    }
  }
  // const handleSearch = (e) => {
  //   e.preventDefault()
  //   const { titleSearch, eventType } = form.getFieldsValue()
  //   const [startDateFrom, startDateTo] = date
  //   // console.log(date)
  //   location.query = {
  //     ...(titleSearch && { s: titleSearch }),
  //     ...(date &&
  //       date.length > 0 && {
  //         from: startDateFrom.format(moment.HTML5_FMT.DATE),
  //         to: startDateTo.format(moment.HTML5_FMT.DATE),
  //       }),
  //     ...(eventType &&
  //       eventType !== 'all' && {
  //         type: eventType,
  //       }),
  //     ...(raceType !== 'all' &&
  //       raceType !== '' && {
  //         rtype: raceType,
  //       }),
  //     p: 1,
  //   }
  // }
  const handlePageChange = (page) => {
    location.query = {
      ...location.query,
      p: page
    }
  }
  const handleExportData = (data) => {
    return (
      data?.eventList?.items?.map((item) => {
        return {
          [intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })]: item.title,
          [intl.formatMessage({ id: 'app.startDate', defaultMessage: 'Start Date' })]: moment(item.startDate).format('Do MMM YYYY'),
          [intl.formatMessage({ id: 'app.endDate', defaultMessage: 'End Date' })]: moment(item.shareEndsAt).format('Do MMM YYYY'),
          [intl.formatMessage({ id: 'app.registrationStartsAt', defaultMessage: 'End Date' })]: item.registrationStartsAt && moment(item.registrationStartsAt).format('Do MMM YYYY'),
          [intl.formatMessage({ id: 'app.enablePartner', defaultMessage: 'Enable Partner' })]: item?.officialPartner ? 'Partner' : '',
          [intl.formatMessage({ id: 'app.photoFaceSearch', defaultMessage: 'Face Search' })]:
            item.photoTagFaces && item.photoTagText
              ? `${intl.formatMessage({ id: 'app.face', defaultMessage: 'Face' })}, ${intl.formatMessage({ id: 'app.bib', defaultMessage: 'BIB' })}`
              : item.photoTagFaces && !item.photoTagText
              ? `${intl.formatMessage({ id: 'app.face', defaultMessage: 'Face' })}`
              : !item.photoTagFaces && item.photoTagText
              ? `${intl.formatMessage({ id: 'app.bib', defaultMessage: 'BIB' })}`
              : '',
          [intl.formatMessage({ id: 'app.amountSold', defaultMessage: 'Amount Sold' })]: item.accessibleShareAmount,
          [intl.formatMessage({ id: 'app.orderCount', defaultMessage: 'Order Count' })]: item.accessibleOrderCount,
          [intl.formatMessage({ id: 'app.totalPhotos', defaultMessage: 'Total Photos' })]: item.accessiblePhotoCount,
          [intl.formatMessage({ id: 'app.salePercentage', defaultMessage: 'Sale Percentage' })]: item.salePercentage,
          [intl.formatMessage({ id: 'app.sales/Picture', defaultMessage: 'sales/Picture' })]: (item.accessibleShareAmount/item.accessiblePhotoCount).toFixed(2),
          [intl.formatMessage({ id: 'app.couponCode', defaultMessage: 'Coupon Code' })]: item.accessibleCouponOrderCount,
        }
      }) ?? []
    )
  }

  if (eventListError) {
    return 'Error'
  }
  if (eventListLoading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  const { eventList } = eventListData || {}
  // const { findEventSummary } = findEventSummaryData || {}
  // const { RangePicker } = DatePicker
  const { items = [], pageInfo } = eventList
  const { currentPage, itemCount, perPage } = pageInfo
  // console.log({items})
  // const exportData = findEventSummary?.map((item, index) => dataRow(item, index))
  // console.log({ items })
  return (
    <div>
      {/* {['superadmin', 'admin', 'accounting'].includes(user.role) && (
        <div style={{ display: 'flex', flexDirection: 'row-reverse', margin: '6px' }}>
          <Button
            icon={<FileExcelOutlined />}
            type='primary'
            size='small'
            disabled={loadingFind}
            onClick={() => exportToCSV([exportData], `event_summary.xlsx`, ['Sheet 1'])}
          >
            <FormattedMessage id='app.export' defaultMessage='Export' />
          </Button>
        </div>
      )} */}
      <div className='filterTools' style={{ textAlign: 'right', padding: '6px' }}>
        <Input.Group compact={!isMobile}>
          <ExportData
            exportedData={handleExportData}
            fileName={`Sales by Event Report_${moment().format('DD_MM_YYYY')}.xlsx`}
            filter={{
              titleSearch,
              eventType,
              raceType,
              isAccessibleOnly: true,
              ...(startDateFrom && startDateTo
                ? { startDate: { gte: moment(startDateFrom).toISOString(), lte: moment(startDateTo).endOf('day').toISOString() } }
                : {}),
            }}
            lazyQuery={eventListQuery}
            sheetNames={['Sales by Event']}
            style={{ margin: '0.3em 1em 1em 0', borderRadius: '5px' }}
          />
          <RaceTypeSelect value={raceType} onChange={handleRaceTypeChange} style={isMobile
            ? { width: '100%', marginTop: '.5em' }
            : { width: 220 }}
          />
          <DatePicker.RangePicker
            style={isMobile
              ? { width: '100%', marginTop: '.5em', textAlign: 'center'}
              : { width: 260, textAlign: 'center'}
            }
            format="ll"
            timePicker={false}
            // ranges={{ 'last month': [moment().subtract(1, 'month'), moment()] }}
            value={(startDateFrom && startDateTo) ? [moment(startDateFrom), moment(startDateTo)] : []}
            defaultValue={(startDateFrom && startDateTo) ? [moment(startDateFrom), moment(startDateTo)] : []}
            onChange={handleCompletedDateRange}
          />
          <Input.Search
            allowClear
            onSearch={handleTitleSearchChange}
            style={isMobile
              ? { width: '100%', marginTop: '.5em' }
              : { width: 200 }
            }
          />
        </Input.Group>
      </div>
      <Table
        dataSource={items}
        rowKey='_id'
        scroll={{ x: true }}
        pagination={{
          defaultPageSize: perPage,
          showQuickJumper: true,
          defaultCurrent: currentPage,
          current: currentPage,
          total: itemCount,
          // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          onChange: handlePageChange
        }}
      >
        <Table.Column
          key='title'
          title={<FormattedMessage id='app.event' defaultMessage='Event' />}
          dataIndex={['title']}
          // render={(title, { officialPartner }) => `${title} ${officialPartner ? '| PARTNER' : ''}` }
          render={(title, { officialPartner, registrationMethod, shareEndsAt, photoPackages, startDate }) => (
            <>
              {moment(shareEndsAt) > moment() ? (
                <CheckCircleFilled style={{ fontSize: '1rem', color: '#32CD32' }} />
              ) : (
                <ClockCircleFilled style={{ fontSize: '1rem', color: '#FFBF00' }} />
              )}
              &nbsp;
              {title}&nbsp;
              {officialPartner && (
                <Tag color='orange'>
                  <FormattedMessage id='app.partner' defaultMessage='Partner' />
                </Tag>
              )}
              {/* {registrationMethod === 'free' ? (
                <Tag color='green'>
                  <FormattedMessage id='app.open' defaultMessage='Open' />
                </Tag>
              ) : (
                <Tag color='purple'>
                  <FormattedMessage id='app.limit' defaultMessage='Limit' />
                </Tag>
              )} */}
              {photoPackages.length > 0 && <Popover
                content={
                  <dive style={{ whiteSpace: 'pre-line' }}>{photoPackages?.map(photoPackage => <Popover key={photoPackage._id} content={photoPackage.description}><Row><Tag color="orange"><TagOutlined /> {photoPackage.name}</Tag></Row></Popover>)}
                  </dive>
                }
              >
                <Tag color="orange"><TagOutlined /> {photoPackages.length} promo</Tag>
              </Popover>}
              &nbsp;
              <div>
                {moment(startDate).format('Do MMM YYYY')}
                {` - `}
                {shareEndsAt && moment(shareEndsAt).format('Do MMM YYYY')}
              </div>
            </>
          )}
        />
        <Table.Column
          key='faceSearch'
          title={<FormattedMessage id='app.photoFaceSearch' defaultMessage='Face Search' />}
          dataIndex={['photoTagFaces']}
          render={(photoTagFaces, { photoTagText }) =>
            photoTagFaces && photoTagText
              ? `${intl.formatMessage({ id: 'app.face', defaultMessage: 'Face' })}, ${intl.formatMessage({ id: 'app.bib', defaultMessage: 'BIB' })}`
              : photoTagFaces && !photoTagText
              ? `${intl.formatMessage({ id: 'app.face', defaultMessage: 'Face' })}`
              : !photoTagFaces && photoTagText
              ? `${intl.formatMessage({ id: 'app.bib', defaultMessage: 'BIB' })}`
              : ''
          }
        />
        <Table.Column
          key='grandTotalAmount'
          title={<FormattedMessage id='app.grandTotal' defaultMessage='Grand Total' />}
          dataIndex={['grandTotalAmount']}
          // eslint-disable-next-line react/style-prop-object
          render={(grandTotalAmount) => <FormattedNumber value={grandTotalAmount} style='currency' currency={config.currency} currencyDisplay='code' />}
        />
        <Table.Column
          key='accessibleShareAmount'
          title={<FormattedMessage id='app.total' defaultMessage='Total' />}
          dataIndex={['accessibleShareAmount']}
          // eslint-disable-next-line react/style-prop-object
          render={(accessibleShareAmount) => <FormattedNumber value={accessibleShareAmount} style='currency' currency={config.currency} currencyDisplay='code' />}/* eslint-disable-line react/style-prop-object */
          // sorter={(a, b) => a.accessibleShareAmount - b.accessibleShareAmount}
          // sortDirections={['descend', 'ascend']}
        />
        <Table.Column
          key='accessibleOrderCount'
          title={<FormattedMessage id='app.orderCount' defaultMessage='Order Count' />}
          dataIndex={['accessibleOrderCount']}
          render={(accessibleOrderCount, { _id, title }) => (
            <Popover
              title={title}
              content={<PhotoSalePopOverContent _id={_id} />}
            >
              <div style={{ color: '#1890ff' }}>{<FormattedNumber value={accessibleOrderCount} />}</div>
            </Popover>
          )}
        />
        <Table.Column
          key='accessiblePhotoCount'
          title={<FormattedMessage id='app.totalPhotos' defaultMessage='Total Photos' />}
          dataIndex={['accessiblePhotoCount']}
          render={(accessiblePhotoCount) => <FormattedNumber value={accessiblePhotoCount} />}
          // sorter={(a, b) => a.accessiblePhotoCount - b.accessiblePhotoCount}
          // sortDirections={['descend', 'ascend']}
        />
        <Table.Column
          key='salePercentage'
          title={<FormattedMessage id='app.salePercentage' defaultMessage='Sale Percentage' />}
          // eslint-disable-next-line react/style-prop-object
          render={(record) => (
            <FormattedNumber
              value={(record.accessiblePhotoSoldCount / record.accessiblePhotoCount) || 0}
              style='percent' /* eslint-disable-line react/style-prop-object */
              maximumFractionDigits={2}
            />
          )}
          // sorter={(a, b) => a.accessiblePhotoSoldCount / a.accessiblePhotoCount - b.accessiblePhotoSoldCount / b.accessiblePhotoCount}
          // sortDirections={['descend', 'ascend']}
        />
         <Table.Column
          key='salesPictur'
          title={<FormattedMessage id='app.sales/Picture' defaultMessage='sales/Pictur'/>}
          // eslint-disable-next-line react/style-prop-object
          render={(record) => (
            <FormattedNumber
              value={(record.accessibleShareAmount / record.accessiblePhotoCount) || 0}
              // style='percent' /* eslint-disable-line react/style-prop-object */
              maximumFractionDigits={2}
            />
          )}
          // sorter={(a, b) => a.accessiblePhotoSoldCount / a.accessiblePhotoCount - b.accessiblePhotoSoldCount / b.accessiblePhotoCount}
          // sortDirections={['descend', 'ascend']}
        />
        <Table.Column
          key='accessibleCouponOrderCount'
          title={<FormattedMessage id='app.couponCode' defaultMessage='Coupon Code' />}
          dataIndex={['accessibleCouponOrderCount']}
          render={(accessibleCouponOrderCount, { _id, title }) => (
            <Popover
              title={title}
              content={<CouponUsagePopOverContent _id={_id} />}
            >
              <div style={{ color: '#1890ff' }}>{<FormattedNumber value={accessibleCouponOrderCount} />}</div>
            </Popover>
          )}
        />
        {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) && <Table.Column
          title={<FormattedMessage id="app.partnerData" defaultMessage="Partner Data" />}
          key="partnerData"
          render={({ _id, officialPartner, organizer, organizerShare, organizerShareAmount, organizerShareUnit, photoSoldCountInSharePeriod, shareEndsAt, startDate, totalShareAmountInSharePeriod }) =>
            officialPartner && <Popover
              content={
                <div style={{ whiteSpace: 'pre-line' }}>{`
                ${intl.formatMessage({ id: 'app.eventID', defaultMessage: 'Event ID' })}: ${_id}
                ${intl.formatMessage({ id: 'app.partner', defaultMessage: 'Partner' })}:
              `}
                {organizer && <Link to={`/events:balance:organizer/${organizer._id}`}>
                  {[organizer.name, organizer.formalName, organizer.companyName].filter(v => !!v).join('\n')}
                </Link>}
                {`
              ${intl.formatMessage({ id: 'app.photoSold', defaultMessage: 'Photo Sold' })} ${moment(shareEndsAt).diff(startDate, 'days') + 1} ${intl.formatMessage({ id: 'app.day', defaultMessage: 'Day' })}: ${intl.formatNumber(totalShareAmountInSharePeriod)}

                ${intl.formatMessage({ id: 'app.totalCommission', defaultMessage: 'Commission' })} ${moment(shareEndsAt).diff(startDate, 'days') + 1} ${intl.formatMessage({ id: 'app.day', defaultMessage: 'Day' })}
                ${intl.formatMessage({ id: 'app.photoItems', defaultMessage: 'Photo items' })} : ${photoSoldCountInSharePeriod}
                ${intl.formatMessage({ id: 'app.totalPartnerShare', defaultMessage: 'Total Partner Share' })}: ${intl.formatNumber(organizerShareAmount, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                
                ${intl.formatMessage({ id: 'app.sharingType', defaultMessage: 'Sharing Type' })}: ${intl.formatNumber(organizerShare, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${organizerShareUnit === 'fixed' ? `${intl.formatMessage({ id: 'app.baht', defaultMessage: 'Baht' })}` : `%`}
              `}
                </div>
              }
            >
              <Button icon={<InfoCircleOutlined />} />
            </Popover>}
        />
        }
      </Table>
    </div>
  )
}

export default compose(inject('user'), inject('router'), observer, injectIntl)(EventSummary)
